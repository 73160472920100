import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
gsap.registerPlugin(ScrollTrigger);

const ThreeImages = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { y: 50, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 90%",
              end: "top 90%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { y: 50, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "top 40%",
          scrub: 2,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);
  return (
    <ParentContainer containerStyle={"flex"} containerHeight={"pb-20 !px-2"}>
      <div ref={main} className="w-full">
        <div
          className="flex gap-5 items-center max-md:gap-5 relative "
          ref={parentRef}
        >
          <div className="swiper-button-prev9 z-[99] hidden max-md:block">
            <svg
              className="rtl:rotate-180"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="matrix(0 -1 -1 0 50 50)"
                fill="#37424A"
              />
              <path
                d="M23.7246 21.4235L20.1348 24.9627L23.7246 28.502"
                fill="white"
              />
              <path
                d="M23.7246 21.4235L20.1348 24.9627L23.7246 28.502"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M28.71 25.0034L20.4304 25.0034"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            modules={[Navigation, Autoplay]}
            navigation={{
              nextEl: ".swiper-button-next9",
              prevEl: ".swiper-button-prev9",
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 5,
                //old value 1
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 1,
                spaceBetween: 5,
                //old value 2
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 1,
                spaceBetween: 5,

                //old value 2
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 1,
                spaceBetween: 30,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            className="w-full"
          >
            <SwiperSlide>
              <div
                className={`w-full flex flex-wrap justify-center gap-5 max-md:w-full imgs ${
                  isVisible ? "animate__animated animate__fadeInUp" : ""
                }`}
              >
                <img
                  width={250}
                  src={ApiUrl + data.Safe_Section_Image_One.data.attributes.url}
                  alt={
                    data.Safe_Section_Image_One.data.attributes
                      .alternativeText ?? ""
                  }
                />
                <h4 className="w-full leading-10 text-center text-3xl max-md:text-2xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                  {data.Safe_Section_Heading_One}
                </h4>
                <p className="w-4/5 text-center text-lg max-md:text-base">
                  {data.Safe_Section_Para_One}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={`w-full flex flex-wrap justify-center gap-5 max-md:w-full imgs${
                  isVisible
                    ? "animate__animated animate__fadeInUp animate__delay-1s"
                    : ""
                }`}
              >
                <img
                  width={250}
                  src={ApiUrl + data.Safe_Section_Image_Two.data.attributes.url}
                  alt={
                    data.Safe_Section_Image_Two.data.attributes
                      .alternativeText ?? ""
                  }
                />
                <h4 className="w-full leading-10 text-center text-3xl max-md:text-2xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                  {data.Safe_Section_Heading_Two}
                </h4>
                <p className="w-4/5 text-center text-lg max-md:text-base  ">
                  {data.Safe_Section_Para_Two}
                </p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className={`w-full flex flex-wrap justify-center gap-5 max-md:w-full imgs ${
                  isVisible
                    ? "animate__animated animate__fadeInUp animate__delay-2s"
                    : ""
                }`}
              >
                <img
                  width={250}
                  src={
                    ApiUrl + data.Safe_Section_Image_Three.data.attributes.url
                  }
                  alt={
                    data.Safe_Section_Image_Three.data.attributes
                      .alternativeText ?? ""
                  }
                />
                <h4 className="w-full leading-10 text-center text-3xl max-md:text-2xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
                  {data.Safe_Section_Heading_Three}
                </h4>
                <p className="w-4/5 text-center text-lg max-md:text-base  ">
                  {data.Safe_Section_Para_Three}
                </p>
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="swiper-button-next9 z-[99] hidden max-md:block">
            <svg
              className="rtl:rotate-180"
              width="50"
              height="50"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="25"
                cy="25"
                r="25"
                transform="rotate(-90 25 25)"
                fill="#37424A"
              />
              <path
                d="M26.2754 20.6282L29.8652 24.3605L26.2754 28.0928"
                fill="white"
              />
              <path
                d="M26.2754 20.6282L29.8652 24.3605L26.2754 28.0928"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M21.29 24.4038L29.5696 24.4038"
                stroke="white"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_590_5719"
                  x1="-4.74138"
                  y1="5.55556"
                  x2="58.7123"
                  y2="10.0354"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#8884D5" />
                  <stop offset="1" stop-color="#CF0072" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default ThreeImages;
