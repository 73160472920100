import React, { useEffect, useState } from "react";
import HeroSection from "./Contact/HeroSection";
import Support from "./Contact/Support";
import Form from "./Contact/Form";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [contactData, setContactData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchContactData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/contact?populate=seo,seo.keywords&locale=ar"
        );
        setContactData(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchContactData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!contactData) {
    return <div>Error loading data.</div>;
  }

  return (
    <div dir="rtl">
      <Helmet>
        <title>{contactData?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={contactData?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            contactData?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet>
      <Support data={contactData.data.attributes} />
      <Form data={contactData.data.attributes} />
      <HeroSection data={contactData.data.attributes} />
    </div>
  );
};

export default Contact;
