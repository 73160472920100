import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import HeroSection from "./GiftRegistry/HeroSection";
import LifeTimeBanner from "../components/LifeTimeBanner";
import Form from "./GiftRegistry/Form";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import { Helmet } from "react-helmet";

const GiftRegistry = () => {
  const [giftPage, setGiftPage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/gift-registry?populate=Image,seo,seo.keywords"
        );
        setGiftPage(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!giftPage) {
    return <div>Error loading data.</div>;
  }

  return (
    <div>
      {/* <Helmet>
        <title>{giftPage?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={giftPage?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            giftPage?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet> */}
      <HeroSection data={giftPage.data.attributes} />
      <LifeTimeBanner Heading={giftPage.data.attributes.Banner_Text} />
      <Form />
    </div>
  );
};

export default GiftRegistry;
