import React from "react";
import { Link } from "react-router-dom";
import { ApiUrl } from "../../components/ApiUrl";

const Documents = ({ data }) => {
  const downloadIcon = (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 2.3335V9.3335L16.3333 7.00016"
        stroke="url(#paint0_linear_590_14016)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.0003 9.33333L11.667 7"
        stroke="url(#paint1_linear_590_14016)"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.16667 14C3.5 14 3.5 16.0883 3.5 18.6667V19.8333C3.5 23.0533 3.5 25.6667 9.33333 25.6667H18.6667C23.3333 25.6667 24.5 23.0533 24.5 19.8333V18.6667C24.5 16.0883 24.5 14 19.8333 14C18.6667 14 18.34 14.245 17.7333 14.7L16.5433 15.96C15.1667 17.43 12.8333 17.43 11.445 15.96L10.2667 14.7C9.66 14.245 9.33333 14 8.16667 14Z"
        stroke="url(#paint2_linear_590_14016)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83301 14V9.33338C5.83301 6.98838 5.83301 5.05171 9.33301 4.71338"
        stroke="url(#paint3_linear_590_14016)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22.167 14V9.33338C22.167 6.98838 22.167 5.05171 18.667 4.71338"
        stroke="url(#paint4_linear_590_14016)"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_14016"
          x1="13.7787"
          y1="3.11127"
          x2="16.753"
          y2="3.18127"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_590_14016"
          x1="11.4457"
          y1="7.25926"
          x2="14.4069"
          y2="7.46832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_590_14016"
          x1="1.50862"
          y1="15.2963"
          x2="27.8663"
          y2="18.6458"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_590_14016"
          x1="5.50111"
          y1="5.74523"
          x2="9.96185"
          y2="5.86392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_590_14016"
          x1="18.3351"
          y1="5.74523"
          x2="22.7958"
          y2="5.86392"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );
  return (
    <div className="flex flex-wrap p-8 bg-white max-md:px-0">
      {data.map((doc, index) => (
        <div className="order flex p-4 py-8 rounded-lg my-4 bg-[#F7F6FF] w-full">
          <div key={index} className="w-full flex justify-between flex">
            <h3>{doc.Title}</h3>
            <a href={`ApiUrl + doc.Download.url`}>{downloadIcon}</a>{" "}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Documents;
