import React from "react";
import ParentContainer from "../components/ParentContainer";

const PrivacyPolicy = () => {
  return (
    <ParentContainer>
      <div className="flex flex-col gap-5">
        <h1 className="text-5xl font-bold  text-left  w-1/2  max-md:text-2xl max-lg:w-full max-lg:mb-4">
          The CellSave Privacy Policy
        </h1>

        <p>
          At CellSave, your trust and privacy are a top priority. When you
          preserve your newborn’s stem cells with us, we want you to be
          confident that we are good stewards of your personal information
          (defined below). CellSave always strive to manage your personal
          information with integrity and respect and is committed to handling
          your personal information in a responsible manner and securing it with
          administrative, technical, organization and physical safeguards. We
          believe in being honest, direct and transparent when it comes to your
          data.{" "}
        </p>

        <p>
          To provide and improve the websites and services (collectively the
          “Services”), we collect, process, and store “Personal Information,”
          which is information that can identify you directly or indirectly, as
          further described below, such as your name, email or address, or
          information that could reasonably be linked back to you. This Privacy
          Policy describes our practices for collecting, storing and processing
          your Personal Information and the controls we provide you to manage it
          within our Services.
        </p>

        <p>
          Please read the following Privacy Policy carefully to understand how
          information you provide to us, whether online or offline, will be
          treated. CellSave may amend this Privacy Policy from time to time. We
          post the effective date of the Privacy Policy on our website. If we
          make material changes to this policy, we will post the revised policy
          and effective date on this website. Please check back here
          periodically or contact us at the address listed at the bottom of this
          Privacy Policy.
        </p>

        <p class="tab-title">
          <strong>
            What Personal Information Does CellSave Collect From You?
          </strong>
        </p>

        <div class="tab-content">
          <p>
            When you visit the CellSave website or talk to one of our CellSave
            Customer Service Agents you can choose to not reveal any Personal
            Information. But if you choose to provide your Personal Information
            to us, you explicitly agree to our collection and use of such
            information, as described in this Privacy Policy.
          </p>

          <p className="pb-5">
            When you enroll with our Services and purchase a stem cell
            collection kit, CellSave will asks for certain personal information,
            including, but not limited to:{" "}
          </p>

          <figure class="wp-block-table overflow-x-auto pb-5">
            <table class="min-w-full border border-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-4 py-2 border border-gray-200 text-left font-semibold">
                    Information Category
                  </th>
                  <th class="px-4 py-2 border border-gray-200 text-left font-semibold">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white">
                  <td class="px-4 py-2 border border-gray-200">
                    Account information
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Your name (required). An email address (required). A
                    password that you create (required). A phone number – You
                    may provide a phone number so we can (i) notify you of test
                    result status, (ii) notify you of other updates you agree
                    to, or (iii) assist you when you contact CellSave Customer
                    Service (phone number is required for collection kit
                    deliveries). A mailing address (required for collection kit
                    deliveries). Billing information.
                  </td>
                </tr>
                <tr class="bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200">
                    Credit Card/Payment Information
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Payment information, such as your credit card number, and
                    your billing and shipping address(es), when you make a
                    purchase of a stem cell collection kit.
                  </td>
                </tr>
                <tr class="bg-white">
                  <td class="px-4 py-2 border border-gray-200">
                    Health-related information
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    When you enroll in stem cell banking, CellSave will ask for
                    certain health-related information including: the expected
                    due date, your health care plan, delivery hospital, and
                    doctor or midwife.
                  </td>
                </tr>
                <tr class="bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200">
                    Contests and Promotions
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    We collect personal information when you voluntarily
                    participate in contests and special promotions we run or
                    sponsor, the details of which are provided upon entry.
                  </td>
                </tr>
                <tr class="bg-white">
                  <td class="px-4 py-2 border border-gray-200">
                    Refer a friend or family member
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    We collect the information you provide about that person,
                    such as name, mailing address, email address, and phone
                    number.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>

          <p clas>
            CellSave also collects information that does not personally identify
            you. This may include:
          </p>

          <figure class="wp-block-table pt-5 overflow-x-auto">
            <table class="min-w-full border border-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-4 py-2 border border-gray-200 text-left font-semibold">
                    Information Category
                  </th>
                  <th class="px-4 py-2 border border-gray-200 text-left font-semibold">
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Computer and Mobile Device Information
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    CellSave may collect information about how you access our
                    Services, including the website you visited before and after
                    CellSave’s site. We also may collect the IP address where
                    your computer, mobile device, or the proxy server that you
                    use accesses the internet, in addition to other technical
                    information, such as:
                    <ul class="list-disc list-inside mt-2">
                      <li>Your computer operating system;</li>
                      <li>Your web browser;</li>
                      <li>
                        Your mobile device identifier provided by your mobile
                        device operating system and your mobile operating
                        system;
                      </li>
                      <li>
                        The name of your internet service provider or mobile
                        carrier.
                      </li>
                    </ul>
                    This information may be aggregated to measure certain
                    traffic patterns on our websites, such as the number of
                    visits, average time spent on the website, pages viewed, and
                    so on. CellSave may collect geolocation information from
                    your device with your permission.
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Information from Cookies and Similar Technologies
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    CellSave uses cookies and similar technologies as well as
                    technical integrations with marketing and advertising
                    partners as described in the below section.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>

        <p class="tab-title">
          <strong>What Cookies Does CellSave use?</strong>
        </p>

        <div class="tab-content">
          <p>
            Cookies are small text files, stored in your browser or on your hard
            drive, that allow us to differentiate between you and all our other
            visitors. Most sites you visit use cookies to improve your online
            experience—and we are no different. They help site providers with
            things like remembering a user’s login details, storing site
            preferences and understanding how a user interacts with a site. We
            use cookies in accordance with this Privacy Policy to:
          </p>

          <ul class="wp-block-list list-disc ml-4">
            <li>ensure that our services function properly;</li>

            <li>detect and prevent fraud;</li>

            <li>understand how visitors use and engage with our site;</li>

            <li>set your preferences;</li>

            <li>deliver relevant advertising; and</li>

            <li>analyze and improve services.</li>
          </ul>

          <p>There are two main types of cookies that can be set:</p>

          <ul class="wp-block-list list-disc ml-4">
            <li>
              First-party cookies: these cookies are placed by CellSave directly
              when you use our services. First party cookies may include cookies
              from service providers.
            </li>

            <li>
              Third-party cookies: these cookies do not belong to CellSave. They
              are owned by other companies, like Google or YouTube, and are used
              for site analytics and advertising purposes. See further details
              below on how to manage these cookies. Third-party cookies will be
              providing a service to CellSave or a function to us but CellSave
              cannot control how those third-party cookies are used.
            </li>
          </ul>

          <p> </p>

          <figure class="wp-block-table pt-5 overflow-x-auto pb-5">
            <table class="min-w-full border border-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    Categories of use (cookies and tracking technologies)
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Necessary
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    These cookies are essential for the operation of our
                    Services. They ensure that your password works, keep you
                    logged in as you navigate between pages, and remember
                    details like what is in your shopping cart or how far you
                    are through an order. Necessary cookies cannot be removed or
                    turned off. We use these cookies in various ways, including:
                    <ul class="list-disc list-inside mt-2">
                      <li>
                        <strong>Authentication:</strong> To remember your login
                        state so you don’t have to log in as you navigate
                        through our website.
                      </li>
                      <li>
                        <strong>Fraud prevention and detection:</strong> Cookies
                        and similar technologies help us learn about the
                        computers and web browsers used to access the Services,
                        aiding in monitoring and detecting potentially harmful
                        or illegal use of our services.
                      </li>
                      <li>
                        <strong>Security:</strong> To protect your data from
                        unauthorized access.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Analytics
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Analytics cookies help us understand how users interact with
                    our Services. We use these cookies to analyze and improve
                    our services by understanding how people reach our website
                    and gaining insights into necessary improvements or
                    enhancements. Specifically, we use them to:
                    <ul class="list-disc list-inside mt-2">
                      <li>Improve site functionality.</li>
                      <li>Understand user interests.</li>
                      <li>Measure content effectiveness.</li>
                    </ul>
                  </td>
                </tr>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Advertising
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Advertising cookies are used to better understand your
                    interests and display ads from CellSave and our third-party
                    advertising partners that are more relevant to you. These
                    cookies may:
                    <ul class="list-disc list-inside mt-2">
                      <li>
                        Share data with advertisers (online behavioral
                        advertising) to make ads more relevant.
                      </li>
                      <li>
                        Limit the number of times you see an advertisement.
                      </li>
                      <li>
                        Allow sharing of certain pages with social networks.
                      </li>
                      <li>
                        Work with third parties to place ads around our related
                        brands’ sites.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Preference
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Preference cookies allow our site to operate optimally and
                    remember your preferences when you have an account with us
                    (e.g., username, language, or region). They also help us
                    understand how our site is used, enabling us to measure and
                    improve its performance.
                  </td>
                </tr>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Pixels
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    A pixel (also known as web beacons or action tags) is a tiny
                    image found within web pages and emails that requires a call
                    to our servers to be rendered. We use pixels to:
                    <ul class="list-disc list-inside mt-2">
                      <li>
                        Learn about your interactions with email or web content
                        (e.g., whether you opened an email or interacted with
                        ads).
                      </li>
                      <li>
                        Allow us and third parties to place cookies on your
                        browser.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Local Storage
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    Local storage enables a website or application to store
                    information locally on your device(s). It may be used to:
                    <ul class="list-disc list-inside mt-2">
                      <li>Improve the CellSave experience.</li>
                      <li>Enable features.</li>
                      <li>Remember your preferences.</li>
                      <li>Speed up site functionality.</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>

          <p>
            You can delete cookies or set your browser to block cookies. Please
            check your browser for instructions on how to do this or follow the
            instructions below to understand your options. If you choose to
            disable cookies, some features of our site and services may not
            operate as intended. Generally, you can find out more about cookies
            and get advice discovering what cookies have been set and how you
            can manage and delete them at{" "}
            <a href="https://www.allaboutcookies.org">
              https://www.allaboutCookies.org
            </a>
            .
          </p>

          <p>
            CellSave notes that although we do our best to honor your privacy
            preferences, and have implemented industry standard safeguards as
            described above, we do not respond to “do-not-track” signals from
            your browser at this time due to the lack of an established industry
            standard.
          </p>
        </div>

        <p class="tab-title">
          <strong>How does CellSave use your personal information? </strong>{" "}
        </p>

        <div class="tab-content">
          <figure class="wp-block-table pt-5 overflow-x-auto">
            <table class="min-w-full border border-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    Information Category
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    Use Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Personal Information (Generally)
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    We use your Personal Information to provide, personalize,
                    improve, update, and expand our Services. This includes:
                    <ul class="list-disc list-inside mt-2">
                      <li>
                        <strong>Authentication:</strong> Authenticating your
                        access to the Services and improving CellSave
                        information security.
                      </li>
                      <li>
                        <strong>Payment Processing:</strong> Processing your
                        payments for subscriptions, CellSave stem cell services,
                        collection kits, and other products and features.
                      </li>
                      <li>
                        <strong>Service Improvement:</strong> Building new and
                        improving existing services.
                      </li>
                      <li>
                        <strong>Surveys and Questionnaires:</strong> Issuing
                        surveys and questionnaires for use in the services, as
                        well as facilitating product development.
                      </li>
                      <li>
                        <strong>Research:</strong> Conducting scientific,
                        statistical, and historical research.
                      </li>
                      <li>
                        <strong>Fraud Detection:</strong> Detecting and
                        protecting against error, fraud, or other criminal or
                        malicious activity.
                      </li>
                      <li>
                        <strong>Agreement Enforcement:</strong> Enforcing our
                        Client Service Agreement.
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Communications
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    We use your Personal Information to communicate with you
                    about the Services, such as when we:
                    <ul class="list-disc list-inside mt-2">
                      <li>Respond to your inquiries to Customer Service.</li>
                      <li>
                        Inform you of changes to Services, new Services, or
                        account updates.
                      </li>
                      <li>
                        Ask you to participate in CellSave media productions,
                        advertisements, consumer insights, or testimonials.
                      </li>
                      <li>
                        Provide you with information or request action in
                        response to technical, security, and other operational
                        issues.
                      </li>
                      <li>
                        Conduct internal auditing, data analysis, and research
                        to improve CellSave’s products, Services, and customer
                        communications.
                      </li>
                    </ul>
                    When you sign up for CellSave’s gift registry, we may use
                    and/or display your full name, state, and expected due date
                    to personalize your information on our gift registry site
                    and/or in an email that we may send on your behalf,
                    announcing your gift registry with CellSave.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>

        <p class="tab-title">
          <strong>What information do we share and with </strong>
          <strong>whom your information may be shared</strong>
          <strong>? </strong>
        </p>

        <div class="tab-content">
          <p>
            CellSave does not share your individual Personal Information with
            third-parties except as described in this Privacy Policy or with
            your additional consent.{" "}
          </p>

          <p>Those with whom your Information </p>

          <figure class="wp-block-table pt-5 overflow-x-auto">
            <table class="min-w-full border border-gray-200">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    May Be Shared
                  </th>
                  <th
                    scope="col"
                    class="px-4 py-2 border border-gray-200 text-left font-semibold"
                  >
                    Description
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Service Provider
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    We work with other companies when providing and marketing
                    the Services. As a result, these companies will have access
                    to or otherwise process your data, including some of your
                    Personal Information, in their systems. These companies are
                    subject to contractual obligations governing privacy, data
                    security, and confidentiality consistent with applicable
                    laws.
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Healthcare Providers
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    If you provide your healthcare provider’s information to us,
                    CellSave may disclose personally identifiable information
                    you provided us with to that provider.
                  </td>
                </tr>
                <tr class="bg-white hover:bg-gray-50">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Advertisement and Marketing Use
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    CellSave will not share your Personal Information with
                    third-parties for their direct marketing purposes.
                  </td>
                </tr>
                <tr class="bg-gray-50 hover:bg-gray-100">
                  <td class="px-4 py-2 border border-gray-200 align-top">
                    Others
                  </td>
                  <td class="px-4 py-2 border border-gray-200">
                    If you request or authorize it, CellSave may disclose
                    personally identifiable information. Under law, legal
                    process, litigation, and/or requests from public and
                    governmental authorities, it may be necessary for CellSave
                    to disclose your Personal Information. We may also disclose
                    information about you if we determine that disclosure is
                    necessary or appropriate for purposes of national security,
                    law enforcement, or other issues of public importance. We
                    may also disclose information about you if we determine that
                    disclosure is reasonably necessary to enforce our terms and
                    conditions or protect our operations, rights or safety, or
                    the rights or safety of others. In the event of a
                    reorganization, merger, or sale, we may transfer all
                    Personal Information we collect to the relevant third-party.
                  </td>
                </tr>
              </tbody>
            </table>
          </figure>
        </div>

        <p class="tab-title">
          <strong>
            What precautions does CellSave take to maintain and secure personal
            information?
          </strong>
        </p>

        <div class="tab-content">
          <p>
            CellSave maintains a comprehensive information security program
            designed to protect our customers’ Personal Information using
            administrative, physical, and technical safeguards.
          </p>

          <p>
            The specific security measures used are based on the sensitivity of
            the Personal Information collected. We have measures in place to
            protect against inappropriate access, loss, misuse, or alteration of
            Personal Information under our control.
          </p>

          <p>
            CellSave regularly reviews our security and privacy practices and
            enhances them as necessary to help ensure the integrity of our
            systems and your Personal Information.
          </p>

          <p>
            We use secure server software to encrypt Personal Information, and
            we only partner with security companies that meet and commit to our
            security standards. While we cannot guarantee that loss, misuse, or
            alteration of data will not occur, we use appropriate technical and
            organizational measures to prevent this.
          </p>

          <p>
            It is also important for you to guard against unauthorized access to
            your Personal Information by maintaining strong passwords and
            protecting against the unauthorized use of your own computer or
            device.
          </p>
        </div>

        <p class="tab-title">
          <strong>
            How CellSave can edit, update, correct, and/or delete information
            about you?
          </strong>
        </p>

        <div class="tab-content">
          <p>
            We want to make sure to keep the contact information that you
            provide to our site or through our services accurate and up to date.
            If you would like to update or correct your email address, mailing
            address, or other personal information with us, you may call: +971 4
            360 4410, send an email to:{" "}
            <a href="mailto:infoarabia@cellsave.com">infoarabia@cellsave.com</a>{" "}
            or simply edit your information by logging into your account page of
            our website.
          </p>

          <p>
            If you prefer not to receive information from us, you also have the
            right to request that we delete your contact information if you
            choose not to enroll to our services and no longer wish to be
            contacted. To make changes to your personal or contact information,
            or to request that we delete the information that you have provided
            to us through the website or our services, please send an email to
            the following address:{" "}
            <a href="mailto:infoarabia@cellsave.com">infoarabia@cellsave.com</a>
          </p>

          <p>
            If you no longer wish to receive any newsletter to which you
            previously subscribed through this website or any other
            communication from CellSave, please follow the “opt out” link in the
            relevant communication or send us an email to the email address
            above.
          </p>

          <p>
            You may also contact us by mail at: CellSave Arabia, Dubai
            Healthcare City, Al Razi Medical 64, G-01, Dubai, UAE, Attn:
            Customer Service Department.
          </p>
        </div>

        <p>Effective Date: April 22, 2023</p>
      </div>
    </ParentContainer>
  );
};

export default PrivacyPolicy;
