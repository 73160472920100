import React, { useState } from "react";
import ParentContainer from "../components/ParentContainer";
import ProfileSideBar from "../components/ProfileSideBar";
import HeroSection from "./ProfileHealthHistory/HeroSection";
import Attention from "./ProfileHealthHistory/Attention";
import Form from "./ProfileHealthHistory/Form";
import "animate.css";

const ProfileHealthHistory = () => {
  const [isActive, setIsActive] = useState(false);
  return (
    <ParentContainer
      containerHeight={"py-0"}
      containerStyle="bg-[#FBF7F5] max-lg:bg-white flex justify-stretch "
    >
      <div
        onClick={() => setIsActive(true)}
        className="fixed z-20 top-1/2 left-0 lg:hidden cursor-pointer bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-2 py-2 rounded-r-xl font-semibold"
      >
        <svg
          class="feather feather-menu"
          fill="none"
          height="24"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          width="24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="3" x2="21" y1="12" y2="12" />
          <line x1="3" x2="21" y1="6" y2="6" />
          <line x1="3" x2="21" y1="18" y2="18" />
        </svg>
      </div>
      <div className="w-1/5 bg-white border-r max-lg:hidden">
        <ProfileSideBar />
      </div>
      {isActive && (
        <div className=" bg-white border-r fixed left-0 h-screen z-30 max-lg:animate__animated max-lg:animate__fadeInUp">
          <ProfileSideBar mobileState={true} isActive={setIsActive} />
        </div>
      )}

      <div className="w-4/5 max-lg:w-full">
        <HeroSection />
        <Attention />
        <Form />
      </div>
    </ParentContainer>
  );
};

export default ProfileHealthHistory;
