import React, { useEffect, useState } from "react";
import ResourcesMini from "../components/ResourcesMiniNav";
import HeroSection from "./FeaturedMoms/HeroSection";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";

const FeaturedMoms = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/blogs?populate=*&pagination[pageSize]=99"
        );

        setSource(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  const blogData = source.data.filter(
    (blog) =>
      blog.attributes.blog_category?.data?.attributes?.Category ===
      "Featured Moms"
  );

  console.log("Moms", blogData);

  return (
    <div className="bg-white">
      <ResourcesMini />
      <HeroSection data={blogData} />
    </div>
  );
};

export default FeaturedMoms;
