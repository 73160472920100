import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const Power = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  const [beforeDelimiter, afterDelimiter] = data.Power_Heading.split("||");
  const [beforeDelimiter1, afterDelimiter1] = data.Power_Para_One.split("||");
  const [beforeDelimiter2, afterDelimiter2] = data.Power_Para_Two.split("||");

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"bg-white overflow-hidden max-md:pt-5"}>
        <div className="flex justify-center flex-wrap py-10 pb-14 items-center box overflow-hidden">
          <h2 className="text-5xl font-bold text-left w-full max-md:text-2xl max-lg:w-full">
            {beforeDelimiter}
            <span className="text-custom-primary">{afterDelimiter}</span>
          </h2>
        </div>
        <div className="flex flex-wrap overflow-hidden">
          <div
            className={` w-2/5 max-lg:w-full ${
              isVisible ? "animate__animated animate__fadeInLeft" : ""
            }`}
          >
            <img
              src={ApiUrl + data.Power_Image.data.attributes.url}
              alt={data.Power_Image.data.attributes.alternativeText ?? ""}
              className="w-4/5 box max-lg:w-full"
            />
          </div>
          <div
            className={`w-3/5 flex flex-wrap justify-stretch  gap-5 max-lg:w-full max-lg:mt-4 ${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <div className="bg-[#F7F6FF] p-4 rounded-lg w-full flex items-center circle">
              <p className="text-base max-md:text-sm">
                <span className="font-bold"> {beforeDelimiter1}</span>{" "}
                {afterDelimiter1}
              </p>
            </div>
            <div className="bg-[#F7F6FF] p-4 rounded-lg w-full flex items-center circle">
              <p className="text-base max-md:text-sm">
                {" "}
                <span className="font-bold"> {beforeDelimiter2}</span>{" "}
                {afterDelimiter2}
              </p>
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Power;
