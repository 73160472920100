import React, { useEffect, useState } from "react";
import assets from "../assets/assets";
import { Link } from "react-router-dom";
import "animate.css";

const ProfileLink = ({ LinkText, Linkto }) => {
  const [url, setUrl] = useState("");
  const [activeClass, setActiveClass] = useState("");
  const [lang, setLang] = useState();

  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      setLang("ar");
    } else {
      setLang("en");
    }
  }, []);
  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;
    setUrl(currentUrl);

    if (currentUrl.includes("maternal-history")) {
      setActiveClass("Maternal Health History");
    } else if (currentUrl.includes("order-history")) {
      setActiveClass("Order History");
    } else if (currentUrl.includes("documents")) {
      setActiveClass("Documents");
    } else if (currentUrl.includes("refer")) {
      setActiveClass("Referrals");
    } else if (currentUrl.includes("pass")) {
      setActiveClass("Acount Settings");
    } else {
      setActiveClass("");
    }
  }, []);

  return (
    <a
      href={Linkto}
      className={`${
        activeClass == LinkText
          ? "bg-gradient-to-r from-custom-primary to-custom-secondary text-white"
          : ""
      }  hover:bg-gradient-to-r from-custom-primary to-custom-secondary hover:text-white ${
        lang === "ar" ? "pr-3 rounded-r-lg" : "pl-3 rounded-l-lg"
      } py-2 font-semibold`}
    >
      {" "}
      {LinkText}
    </a>
  );
};

const ProfileSideBar = ({ mobileState, isActive, username }) => {
  const [isMobile, setIsMobile] = useState(false);

  const [lang, setLang] = useState();

  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      setLang("ar");
    } else {
      setLang("en");
    }
  }, []);
  const handleLogOut = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("user");
    window.location.href = "/";
  };

  return (
    <div className="max-md:animate__animated max-md:animate__fadeInUp">
      {mobileState && (
        <div
          className=" pr-4 py-4 flex justify-end "
          onClick={() => isActive(false)}
        >
          <svg
            id="Outlined"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            className="w-4 h-4"
          >
            <title />
            <g id="Fill">
              <polygon points="28.71 4.71 27.29 3.29 16 14.59 4.71 3.29 3.29 4.71 14.59 16 3.29 27.29 4.71 28.71 16 17.41 27.29 28.71 28.71 27.29 17.41 16 28.71 4.71" />
            </g>
          </svg>
        </div>
      )}

      <div className="flex gap-2 py-8 justify-center items-center border-b">
        <div className="w-1/2 flex justify-center">
          <img src={assets.profilepic} alt="" className="w-3/4" />
        </div>
        <h1 className="text-2xl w-1/2 font-bold">{username ? username : ""}</h1>
      </div>
      <div
        className={`flex flex-col py-8 ${
          lang === "ar" ? "pr-4" : "pl-4"
        } gap-4`}
      >
        {/* <ProfileLink
          LinkText={"Maternal Health History"}
          Linkto="/profile/maternal-history"
        /> */}
        <ProfileLink
          LinkText={lang === "ar" ? "سجل الطلبات" : "Order History"}
          Linkto={
            lang === "ar"
              ? "/ar/profile/order-history"
              : "/profile/order-history"
          }
        />
        {/* <ProfileLink LinkText={"Documents"} Linkto="/profile/documents" /> */}
        <ProfileLink
          LinkText={lang === "ar" ? "الإحالات" : "Referrals"}
          Linkto={lang === "ar" ? "/ar/profile/refer" : "/profile/refer"}
        />
        {/* <ProfileLink LinkText={"Acount Settings"} Linkto="/profile/pass" /> */}
        <div
          onClick={() => handleLogOut()}
          className={`  hover:bg-gradient-to-r from-custom-primary to-custom-secondary hover:text-white ${
            lang === "ar" ? "pr-3 rounded-r-lg" : "pl-3 rounded-l-lg"
          } py-2   font-semibold`}
        >
          {lang === "ar" ? "تسجيل الخروج" : "Log Out"}
        </div>
      </div>
    </div>
  );
};

export default ProfileSideBar;
