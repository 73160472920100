import React from "react";
import ParentContainer from "../components/ParentContainer";

const PrivacyPolicy = () => {
  return (
    <ParentContainer>
      <div
        dir="rtl"
        className="rich-content"
        dangerouslySetInnerHTML={{
          __html: `
          <style>
  .rich-content table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e5e7eb; /* Tailwind's gray-200 */
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
    color: #374151; /* Tailwind's gray-700 */
    direction: rtl;
    text-align: right;
  }

  .rich-content th {
    background-color: #f3f4f6; /* Tailwind's gray-100 */
    font-weight: 700;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    font-size: 0.875rem;
  }

  .rich-content td {
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    vertical-align: top;
  }

  .rich-content tr:nth-child(even) {
    background-color: #f9fafb; /* Tailwind's gray-50 */
  }

  .rich-content ul,
  .rich-content ol {
    padding-right: 1.5rem;
    margin-bottom: 1rem;
  }

  .rich-content p {
    margin-bottom: 0.75rem;
  }

  .rich-content strong {
    font-weight: 600;
  }
</style>
         
          <p><strong>سياسة الخصوصية لـ CellSave</strong></p>
          <p><strong>&nbsp;</strong></p>
          <p><span style="font-weight: 400;">في </span><strong>CellSave</strong><span style="font-weight: 400;">، تعتبر ثقتك وخصوصيتك من أولوياتنا. عندما تقوم بحفظ خلايا الجذعية لطفلك حديث الولادة معنا، نريدك أن تكون واثقًا أننا نعتني بمعلوماتك الشخصية بشكل جيد. تسعى </span><strong>CellSave</strong><span style="font-weight: 400;"> دائمًا إلى إدارة معلوماتك الشخصية بنزاهة واحترام، وهي ملتزمة بمعالجة معلوماتك الشخصية بطريقة مسؤولة وتأمينها باستخدام تدابير حماية إدارية وتقنية وتنظيمية وفيزيائية. نحن نؤمن بالشفافية والصراحة والوضوح عندما يتعلق الأمر ببياناتك.</span></p>
          <p><span style="font-weight: 400;">من أجل تقديم وتحسين المواقع والخدمات (المجتمعة "الخدمات")، نقوم بجمع ومعالجة وتخزين "المعلومات الشخصية"، وهي المعلومات التي يمكن أن تحدد هويتك بشكل مباشر أو غير مباشر، كما هو موضح أدناه، مثل اسمك، بريدك الإلكتروني أو عنوانك، أو المعلومات التي يمكن ربطها بك بشكل معقول. تصف سياسة الخصوصية هذه ممارساتنا في جمع وتخزين ومعالجة معلوماتك الشخصية وأدوات التحكم التي نوفرها لك لإدارتها ضمن خدماتنا.</span></p>
          <p><span style="font-weight: 400;">يرجى قراءة سياسة الخصوصية التالية بعناية لفهم كيفية التعامل مع المعلومات التي تقدمها لنا، سواء عبر الإنترنت أو غير ذلك. قد تقوم </span><strong>CellSave</strong><span style="font-weight: 400;"> بتعديل سياسة الخصوصية هذه من وقت لآخر. نقوم بنشر تاريخ سريان سياسة الخصوصية على موقعنا الإلكتروني. إذا قمنا بإجراء تغييرات جوهرية على هذه السياسة، سننشر السياسة المعدلة وتاريخ سريانها على هذا الموقع. يرجى التحقق من هنا بشكل دوري أو الاتصال بنا على العنوان المذكور في أسفل سياسة الخصوصية هذه.</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><strong>ما هي المعلومات الشخصية التي تجمعها CellSave منك؟</strong></p>
          <p><span style="font-weight: 400;">عند زيارتك لموقع </span><strong>CellSave</strong><span style="font-weight: 400;"> الإلكتروني أو عند التحدث إلى أحد ممثلي خدمة العملاء لدينا، يمكنك اختيار عدم الكشف عن أي معلومات شخصية. ولكن إذا اخترت تقديم معلوماتك الشخصية لنا، فإنك توافق صراحة على جمعنا واستخدامنا لتلك المعلومات، كما هو موضح في سياسة الخصوصية هذه.</span></p>
          <p><span style="font-weight: 400;">عند تسجيلك في خدماتنا وشراء مجموعة جمع خلايا الجذع، ستطلب </span><strong>CellSave</strong><span style="font-weight: 400;"> منك تقديم بعض المعلومات الشخصية، بما في ذلك، ولكن لا تقتصر على:</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <table>
          <tbody>
          <tr>
          <td>
          <p><strong>فئة المعلومات</strong></p>
          </td>
          <td>
          <p><strong>الوصف</strong></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>معلومات الحساب</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">اسمك (مطلوب). عنوان البريد الإلكتروني (مطلوب). كلمة مرور تقوم بإنشائها (مطلوب). رقم الهاتف &ndash; يمكنك تقديم رقم هاتف لكي نتمكن من (i) إخطارك بحالة نتائج الاختبار، (ii) إخطارك بالتحديثات الأخرى التي توافق عليها، أو (iii) مساعدتك عندما تتصل بخدمة عملاء </span><strong>CellSave</strong><span style="font-weight: 400;"> (رقم الهاتف مطلوب لتسليم مجموعات جمع الخلايا الجذعية). عنوان بريدك (مطلوب لتسليم مجموعات جمع الخلايا الجذعية). معلومات الفوترة.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>معلومات بطاقة الائتمان / الدفع</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">معلومات الدفع مثل رقم بطاقة الائتمان، وعنوان الفوترة والشحن عندما تقوم بشراء مجموعة جمع الخلايا الجذعية.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>معلومات متعلقة بالصحة</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">عند التسجيل في خدمات حفظ الخلايا الجذعية، ستطلب </span><strong>CellSave</strong><span style="font-weight: 400;"> معلومات متعلقة بالصحة مثل: تاريخ الولادة المتوقع، خطة الرعاية الصحية الخاصة بك، المستشفى الذي ستتم فيه الولادة، والطبيب أو القابلة.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>المسابقات والعروض الترويجية</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">نقوم بجمع المعلومات الشخصية عندما تشارك طوعًا في المسابقات والعروض الترويجية الخاصة التي نقوم بها أو نرعاها، حيث يتم توفير تفاصيل ذلك عند التسجيل.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>إحالة صديق أو أحد أفراد الأسرة</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">نقوم بجمع المعلومات التي تقدمها عن تلك الشخص، مثل الاسم، عنوان البريد، عنوان البريد الإلكتروني، ورقم الهاتف.</span></p>
          </td>
          </tr>
          </tbody>
          </table>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">تجمع CellSave أيضًا معلومات لا تحدد هويتك الشخصية. قد تشمل هذه المعلومات:</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <table>
          <tbody>
          <tr>
          <td>
          <p><span style="font-weight: 400;">فئة المعلومات</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">الوصف</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>معلومات الكمبيوتر والأجهزة المحمولة</strong><span style="font-weight: 400;">:</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          <td>
          <p><strong>معلومات الكمبيوتر والأجهزة المحمولة</strong><span style="font-weight: 400;">:</span></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">قد تجمع CellSave معلومات حول كيفية وصولك إلى خدماتنا، بما في ذلك الموقع الإلكتروني الذي زرته قبل وبعد موقع CellSave.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">قد نقوم أيضًا بجمع عنوان IP الذي يستخدمه جهاز الكمبيوتر أو الجهاز المحمول أو الخادم الوسيط الذي تستخدمه للوصول إلى الإنترنت.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">معلومات تقنية أخرى قد تشمل:</span></li>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">نظام تشغيل جهاز الكمبيوتر الخاص بك.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">متصفح الويب الخاص بك.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">معرف جهازك المحمول الذي يقدمه نظام تشغيل جهازك المحمول.</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">اسم مزود خدمة الإنترنت أو مشغل الهاتف المحمول الخاص بك.</span></li>
          </ul>
          </ul>
          <p><span style="font-weight: 400;">قد يتم تجميع هذه المعلومات لقياس أنماط حركة المرور على مواقعنا الإلكترونية، مثل عدد الزيارات، ومتوسط الوقت الذي يقضيه على الموقع، والصفحات التي تم مشاهدتها، وما إلى ذلك.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">معلومات من ملفات تعريف الارتباط والتقنيات المماث</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">تستخدم CellSave ملفات تعريف الارتباط والتقنيات المماثلة وكذلك التكاملات التقنية مع شركاء التسويق والإعلانات كما هو موضح في القسم أدناه.</span></p>
          </td>
          </tr>
          </tbody>
          </table>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">ما هي ملفات تعريف الارتباط (الكوكيز) التي تستخدمها CellSave؟</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">ملفات تعريف الارتباط هي ملفات نصية صغيرة يتم تخزينها في متصفحك أو على محرك الأقراص الثابتة، والتي تسمح لنا بالتمييز بينك وبين جميع زوارنا الآخرين. معظم المواقع التي تزورها تستخدم ملفات تعريف الارتباط لتحسين تجربتك عبر الإنترنت - ونحن لسنا استثناءً. تساعد ملفات تعريف الارتباط مقدمي المواقع في أشياء مثل تذكر تفاصيل تسجيل الدخول للمستخدم، وتخزين تفضيلات الموقع وفهم كيفية تفاعل المستخدم مع الموقع. نستخدم ملفات تعريف الارتباط وفقًا لسياسة الخصوصية هذه:</span></p>
          <ul>
          <li style="font-weight: 400;"><span style="font-weight: 400;">لضمان أن تعمل خدماتنا بشكل صحيح؛</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">للكشف عن ومنع الاحتيال؛</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">لفهم كيفية استخدام الزوار والتفاعل مع موقعنا؛</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">لتعيين تفضيلاتك؛</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">لتقديم إعلانات ملائمة؛</span></li>
          <li style="font-weight: 400;"><span style="font-weight: 400;">ولتحليل وتحسين الخدمات.</span></li>
          </ul>
          <p><span style="font-weight: 400;">هناك نوعان رئيسيان من ملفات تعريف الارتباط التي يمكن تعيينها:</span></p>
          <ol>
          <li style="font-weight: 400;"><strong>ملفات تعريف الارتباط الخاصة بالطرف الأول</strong><span style="font-weight: 400;">: هذه هي ملفات تعريف الارتباط التي تضعها </span><strong>CellSave</strong><span style="font-weight: 400;"> مباشرة عندما تستخدم خدماتنا. قد تشمل ملفات تعريف الارتباط الخاصة بالطرف الأول ملفات من مقدمي الخدمة.</span></li>
          <li style="font-weight: 400;"><strong>ملفات تعريف الارتباط الخاصة بالطرف الثالث</strong><span style="font-weight: 400;">: هذه ملفات تعريف الارتباط التي لا تنتمي إلى </span><strong>CellSave</strong><span style="font-weight: 400;">. إنها مملوكة لشركات أخرى، مثل جوجل أو يوتيوب، وتستخدم لأغراض تحليلات الموقع والإعلانات. راجع المزيد من التفاصيل أدناه حول كيفية إدارة هذه الملفات. توفر ملفات تعريف الارتباط الخاصة بالطرف الثالث خدمة لـ </span><strong>CellSave</strong><span style="font-weight: 400;"> أو وظيفة لنا، لكن </span><strong>CellSave</strong><span style="font-weight: 400;"> لا يمكنها التحكم في كيفية استخدام تلك الملفات.</span></li>
          </ol>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <table>
          <tbody>
          <tr>
          <td>
          <p><strong>فئات الاستخدام (ملفات تعريف الارتباط وتقنيات التتبع)</strong></p>
          </td>
          <td>
          <p><strong>الوصف</strong></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><strong>الضرورية</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">هذه ملفات تعريف الارتباط ضرورية لتشغيل خدماتنا. فهي تضمن أن كلمة المرور الخاصة بك تعمل، وتحافظ على تسجيل دخولك أثناء التنقل بين الصفحات، وتذكر تفاصيل مثل محتويات سلة التسوق الخاصة بك أو مدى تقدمك في الطلب. لا يمكن إزالة أو إيقاف ملفات تعريف الارتباط الضرورية. نستخدم هذه الملفات بطرق متعددة، بما في ذلك:</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">لمصادقة: لتذكر حالة تسجيل الدخول الخاصة بك حتى لا تضطر إلى تسجيل الدخول أثناء التنقل عبر موقعنا</span></p>
          <p><span style="font-weight: 400;">منع الاحتيال والكشف عنه: تساعدنا ملفات تعريف الارتباط والتقنيات المماثلة في التعرف على أجهزة الكمبيوتر ومتصفحات الويب المستخدمة للوصول إلى الخدمات، مما يساعد في مراقبة واكتشاف الاستخدام الضار أو غير القانوني لخدماتنا.</span></p>
          <p><span style="font-weight: 400;">الأمان: لحماية بياناتك من الوصول غير المصرح به.</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><strong>التحليلات</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">تساعدنا ملفات تعريف الارتباط التحليلية في فهم كيفية تفاعل المستخدمين مع خدماتنا. نستخدم هذه الملفات لتحليل وتحسين خدماتنا من خلال فهم كيفية وصول الأشخاص إلى موقعنا واكتساب رؤى حول التحسينات أو التعديلات الضرورية. بشكل محدد، نستخدمها لـ:</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">تحسين وظائف الموقع.</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">فهم اهتمامات المستخدم.</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">قياس فعالية المحتوى.</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>الإعلانات</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">تستخدم ملفات تعريف الارتباط الإعلانية لفهم اهتماماتك بشكل أفضل وعرض إعلانات من </span><strong>CellSave</strong><span style="font-weight: 400;"> وشركائنا في الإعلان من الأطراف الثالثة تكون أكثر صلة بك. قد تقوم هذه الملفات بـ:</span></p>
          <p><span style="font-weight: 400;">مشاركة البيانات مع المعلنين (الإعلانات السلوكية عبر الإنترنت) لجعل الإعلانات أكثر صلة.</span></p>
          <p><span style="font-weight: 400;">تحديد عدد مرات مشاهدة الإعلان</span></p>
          <p><span style="font-weight: 400;">السماح بمشاركة بعض الصفحات مع الشبكات الاجتماعية.</span></p>
          <p><span style="font-weight: 400;">العمل مع أطراف ثالثة لوضع الإعلانات حول مواقع العلامات التجارية ذات الصلة بنا.</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><strong>التفضيلات</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">تسمح ملفات تعريف الارتباط الخاصة بالتفضيلات لموقعنا بالعمل بشكل مثالي وتذكر تفضيلاتك عندما يكون لديك حساب معنا (مثل اسم المستخدم، اللغة أو المنطقة). كما أنها تساعدنا في فهم كيفية استخدام موقعنا، مما يمكننا من قياس وتحسين أدائه.</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><strong>البكسلات</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">البكسل (المعروف أيضًا بالإشارات الإلكترونية أو علامات الإجراءات) هو صورة صغيرة موجودة داخل صفحات الويب ورسائل البريد الإلكتروني تتطلب اتصالًا بخوادمنا لعرضها. نستخدم البكسلات لـ:</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">التعرف على تفاعلاتك مع البريد الإلكتروني أو محتوى الويب (مثل ما إذا كنت قد فتحت بريدًا إلكترونيًا أو تفاعلت مع الإعلانات).</span></p>
          <p><span style="font-weight: 400;">السماح لنا ولأطراف ثالثة بوضع ملفات تعريف الارتباط على متصفحك.</span></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          <tr>
          <td>
          <p><strong>التخزين المحلي</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">يتيح التخزين المحلي لموقع الويب أو التطبيق تخزين المعلومات محليًا على جهازك (أجهزتك). قد يُستخدم لـ:</span></p>
          <p><strong>تحسين تجربةCellSave</strong></p>
          <p><strong>تمكين الميزات.</strong></p>
          <p><strong>تذكر تفضيلاتك.</strong></p>
          <p><strong>تسريع وظائف الموقع.</strong></p>
          </td>
          <td>&nbsp;</td>
          </tr>
          </tbody>
          </table>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">يمكنك حذف ملفات تعريف الارتباط أو ضبط متصفحك لحظر ملفات تعريف الارتباط. يرجى التحقق من متصفحك للحصول على تعليمات حول كيفية القيام بذلك أو اتباع التعليمات أدناه لفهم خياراتك. إذا قررت تعطيل ملفات تعريف الارتباط، قد لا تعمل بعض ميزات موقعنا والخدمات كما هو مقصود. بشكل عام، يمكنك معرفة المزيد عن ملفات تعريف الارتباط والحصول على نصائح حول اكتشاف ملفات تعريف الارتباط التي تم تعيينها وكيفية إدارتها وحذفها على الموقع التالي:</span><a href="https://www.allaboutcookies.org"> <span style="font-weight: 400;">https://www.allaboutCookies.org</span></a><span style="font-weight: 400;">.</span></p>
          <p><span style="font-weight: 400;">تلاحظ </span><strong>CellSave</strong><span style="font-weight: 400;"> أنه على الرغم من أننا نبذل قصارى جهدنا لاحترام تفضيلات الخصوصية الخاصة بك، وقد قمنا بتنفيذ تدابير حماية قياسية في الصناعة كما هو موضح أعلاه، إلا أننا لا نستجيب حالياً لإشارات "عدم التتبع" من متصفحك بسبب عدم وجود معيار معتمد في الصناعة.</span></p>
          <p><span style="font-weight: 400;">كيف يستخدم CellSave معلوماتك الشخصية؟</span></p>
          



          <table>
          <tbody>
          <tr>
          <td width="30%"> وصف الاستخدام


          </td>
          <td width="70%">فئة المعلومات</td>
          </tr>
          <tr>
          <td width="30%">
  المعلومات الشخصية (بشكل عام)<br><br>

</td>
          <td width="70%">
          
        
       نستخدم معلوماتك الشخصية لتقديم وتخصيص وتحسين وتحديث وتوسيع خدماتنا. يشمل ذلك:
 

<br>


المصادقة: التحقق من وصولك إلى الخدمات وتحسين أمان معلومات CellSave. <br><br>

 




معالجة الدفع: معالجة مدفوعاتك للاشتراكات، خدمات الخلايا الجذعية من CellSave، مجموعات الجمع، والمنتجات والميزات الأخرى.<br>
تحسين الخدمة: بناء خدمات جديدة وتحسين الخدمات الحالية.<br>
الاستطلاعات والاستبيانات: إصدار الاستطلاعات والاستبيانات لاستخدامها في الخدمات، وكذلك تسهيل تطوير المنتجات.<br>
البحث: إجراء أبحاث علمية وإحصائية وتاريخية.<br>
الكشف عن الاحتيال: الكشف عن الأخطاء، الاحتيال، أو أي نشاط إجرامي أو خبيث وحمايتك منه.<br>
تنفيذ الاتفاقية: تنفيذ اتفاقية خدمة العملاء الخاصة بنا.<br>





          </td>
          </tr>
          <tr>
          <td width="30%">التواصل</td>
          <td width="70%">
          نستخدم معلوماتك الشخصية للتواصل معك حول الخدمات، مثل:<br>
 الرد على استفساراتك: الرد على استفساراتك إلى خدمة العملاء.<br>
 إعلامك بالتغييرات: إبلاغك بالتغييرات في الخدمات، الخدمات الجديدة، أو تحديثات الحساب.<br>
 طلب المشاركة: طلب منك المشاركة في إنتاجات وسائط CellSave، الإعلانات، رؤى المستهلكين، أو الشهادات.<br>
 توفير المعلومات أو طلب الإجراءات: تزويدك بالمعلومات أو طلب الإجراءات في استجابة للمشاكل التقنية، الأمنية، والعملية الأخرى.<br>
 إجراء التدقيق الداخلي وتحليل البيانات: إجراء التدقيقات وتحليل البيانات والبحث لتحسين منتجات وخدمات CellSave والتواصل مع العملاء.<br>
 التسجيل في هدايا CellSave: عند التسجيل في سجل هدايا CellSave، قد نستخدم و/أو نعرض اسمك الكامل، الولاية، وتاريخ الاستحقاق المتوقع لتخصيص معلوماتك على موقع سجل الهدايا الخاص بنا و/أو في بريد إلكتروني قد نرسله نيابة عنك، للإعلان عن سجل هداياك مع CellSave.


          
          
          </td>
          </tr>
          </tbody>
          </table>


          
          <p><strong>ما هي المعلومات التي نشاركها ومع من قد يتم مشاركة معلوماتك؟</strong></p>
         
         
          <p><strong>لا تقوم CellSave بمشاركة معلوماتك الشخصية الفردية مع أطراف ثالثة باستثناء ما هو موضح في سياسة الخصوصية هذه أو بموافقتك الإضافية.</strong></p>
         
         
         
         
         
          <table>
          <tbody>
          <tr>
          <td>
          <p><strong>قد يتم مشاركتها</strong></p>
          </td>
          <td>
          <p><strong>الوصف</strong></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>مزود الخدمة</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">نعمل مع شركات أخرى عند تقديم وتسويق الخدمات. نتيجة لذلك، سيكون لهذه الشركات الوصول إلى بياناتك أو قد تقوم بمعالجتها، بما في ذلك بعض معلوماتك الشخصية، في أنظمتها. وهذه الشركات تخضع للالتزامات التعاقدية التي تحكم الخصوصية وأمن البيانات والسرية بما يتوافق مع القوانين المعمول بها.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>مقدمو الرعاية الصحية</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">إذا قدمت لنا معلومات عن مزود الرعاية الصحية الخاص بك، قد تكشف CellSave عن معلومات تعريف شخصية قدمتها لنا إلى ذلك المزود.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><strong>استخدام الإعلانات والتسويق</strong></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">لن تشارك CellSave معلوماتك الشخصية مع أطراف ثالثة لأغراض التسويق المباشر.</span></p>
          </td>
          </tr>
          <tr>
          <td>
          <p><span style="font-weight: 400;">الآخرون</span></p>
          </td>
          <td>
          <p><span style="font-weight: 400;">"إذا طلبت أو أذنت بذلك، قد تكشف </span><strong>سيل سيف</strong><span style="font-weight: 400;"> عن معلوماتك الشخصية. بموجب القانون، أو الإجراءات القانونية، أو التقاضي، أو طلبات من السلطات العامة والحكومية، قد يكون من الضروري لـ </span><strong>سيل سيف</strong><span style="font-weight: 400;"> الكشف عن معلوماتك الشخصية. قد نكشف أيضًا عن معلوماتك إذا قررنا أن الكشف ضروري أو مناسب لأغراض الأمن الوطني أو إنفاذ القانون أو مسائل أخرى ذات أهمية عامة. قد نكشف أيضًا عن معلوماتك إذا قررنا أن الكشف ضروري بشكل معقول لتنفيذ شروطنا وأحكامنا أو لحماية عملياتنا أو حقوقنا أو سلامتنا، أو حقوق أو سلامة الآخرين. في حال حدوث إعادة تنظيم أو اندماج أو بيع، قد ننقل جميع المعلومات الشخصية التي نجمعها إلى الطرف الثالث المعني."</span></p>
          </td>
          </tr>
          </tbody>
          </table>
        
        
        
        <p><span style="font-weight: 400;">&nbsp;</span></p>
        
          <p><span style="font-weight: 400;">&nbsp;</span></p>
          <p><span style="font-weight: 400;">ما هي الاحتياطات التي تتخذها </span><strong>سيل سيف</strong><span style="font-weight: 400;"> للحفاظ على المعلومات الشخصية وتأمينها؟</span></p>
          <p><span style="font-weight: 400;">оحتفظ </span><strong>سيل سيف</strong><span style="font-weight: 400;"> ببرنامج شامل لأمن المعلومات مصمم لحماية معلومات عملائنا الشخصية باستخدام تدابير إدارية وبدنية وتقنية.</span></p>
          <p><span style="font-weight: 400;">تستند التدابير الأمنية المحددة إلى حساسية المعلومات الشخصية التي يتم جمعها. لدينا تدابير لحماية المعلومات الشخصية التي تحت سيطرتنا ضد الوصول غير المناسب أو الفقدان أو سوء الاستخدام أو التعديل.</span></p>
          <p><span style="font-weight: 400;">تقوم </span><strong>سيل سيف</strong><span style="font-weight: 400;"> بمراجعة ممارساتنا الأمنية والخصوصية بانتظام وتعزيزها حسب الحاجة لضمان سلامة أنظمتنا ومعلوماتكم الشخصية.</span></p>
          <p><span style="font-weight: 400;">نستخدم برامج خوادم آمنة لتشفير المعلومات الشخصية، ونحن نتعاون فقط مع شركات أمان تلتزم بمعاييرنا الأمنية. على الرغم من أننا لا يمكننا ضمان عدم حدوث فقدان أو سوء استخدام أو تعديل للبيانات، إلا أننا نستخدم تدابير فنية وتنظيمية مناسبة لمنع ذلك.</span></p>
          <p><span style="font-weight: 400;">من المهم أيضًا أن تحمي معلوماتك الشخصية ضد الوصول غير المصرح به عن طريق الحفاظ على كلمات مرور قوية وحماية جهاز الكمبيوتر أو الجهاز الشخصي من الاستخدام غير المصرح به.</span></p>
          <p><span style="font-weight: 400;">كيف يمكن لـ </span><strong>سيل سيف</strong><span style="font-weight: 400;"> تعديل أو تحديث أو تصحيح أو حذف المعلومات عنك؟</span></p>
          <p><span style="font-weight: 400;">نحن نريد التأكد من أن المعلومات الاتصال التي تقدمها إلى موقعنا أو من خلال خدماتنا دقيقة ومحدثة. إذا كنت ترغب في تحديث أو تصحيح عنوان بريدك الإلكتروني أو عنوانك البريدي أو معلوماتك الشخصية الأخرى، يمكنك الاتصال بنا على الرقم: +971 4 360 4410، أو إرسال بريد إلكتروني إلى: infoarabia@cellsave.com أو ببساطة تعديل معلوماتك من خلال تسجيل الدخول إلى صفحة حسابك على موقعنا.</span></p>
          <p><span style="font-weight: 400;">إذا كنت تفضل عدم تلقي المعلومات منا، لديك أيضًا الحق في طلب حذف معلومات الاتصال الخاصة بك إذا اخترت عدم التسجيل في خدماتنا ولم تعد ترغب في الاتصال بك. لإجراء تغييرات على معلوماتك الشخصية أو الاتصال، أو لطلب حذف المعلومات التي قدمتها لنا من خلال الموقع أو خدماتنا، يرجى إرسال بريد إلكتروني إلى العنوان التالي: infoarabia@cellsave.com</span></p>
          <p><span style="font-weight: 400;">إذا كنت لا ترغب في تلقي أي نشرات إخبارية كنت قد اشتركت بها سابقًا عبر هذا الموقع أو أي تواصل آخر من </span><strong>سيل سيف</strong><span style="font-weight: 400;">، يرجى اتباع رابط "إلغاء الاشتراك" في الاتصال المعني أو إرسال بريد إلكتروني إلى العنوان السابق.</span></p>
          <p><span style="font-weight: 400;">يمكنك أيضًا الاتصال بنا عبر البريد على العنوان التالي: </span><strong>سيل سيف العربية، مدينة دبي الطبية، المجمع الطبي الرازي 64، G-01، دبي، الإمارات العربية المتحدة، قسم خدمة العملاء</strong><span style="font-weight: 400;">.</span></p>
          <p><span style="font-weight: 400;">تاريخ السريان: 22 أبريل 2023</span></p>
          <p>&nbsp;</p>

      `,
        }}
      />
    </ParentContainer>
  );
};

export default PrivacyPolicy;
