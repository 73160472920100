import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const Introduction = ({ data }) => {
  const [beforeDelimiter, afterDelimiter] =
    data.data.attributes.Regen_Med_Heading.split("||");
  const [isVisible1, setIsVisible1] = useState(false);
  const main = useRef();
  const observerRef = useRef();

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       if (entries[0].intersectionRatio >= 0.1) {
  //         observerRef.current = true;
  //         observer.disconnect();
  //         initializeScrollTrigger();
  //       }
  //     },
  //     {
  //       threshold: [0.1],
  //     }
  //   );

  //   observer.observe(main.current);

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  useEffect(() => {
    const initializeScrollTrigger = () => {
      const ctx = gsap.context(() => {
        const boxes = gsap.utils.toArray(".boxInt");
        boxes.forEach((box) => {
          gsap.fromTo(
            box,
            { x: 200, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              scrollTrigger: {
                trigger: box,
                start: "top 70%",
                end: "top 40%",
                scrub: 1,
                // markers: true,
              },
            }
          );
        });

        const circles = gsap.utils.toArray(".circle");
        circles.forEach((circle) => {
          gsap.fromTo(
            circle,
            { x: 500, scale: 0 },
            {
              x: 0,
              scale: 1,
              scrollTrigger: {
                trigger: circle,
                start: "bottom 0%",
                end: "top 90%",
                scrub: 1,
                // markers: true,
              },
            }
          );
        });

        const images = gsap.utils.toArray(".imgs");
        images.forEach((imgs) => {
          gsap.fromTo(
            imgs,
            { opacity: 0, x: -300 },
            {
              opacity: 1,
              x: 0,
              scrollTrigger: {
                trigger: imgs,
                start: "top 70%",
                end: "top 40%",
                scrub: 2,
                //  markers: true,
              },
            }
          );
        });

        const bgssss = gsap.utils.toArray(".bgs");
        bgssss.forEach((bgs) => {
          gsap.fromTo(
            bgs,
            { background: "linear-gradient(to right, white, white)" },
            {
              background: "linear-gradient(to right, #37424A, black)",
              scrollTrigger: {
                trigger: bgs,
                start: "bottom 50%",
                end: "top -50%",
                scrub: 1,
                // markers: true,
              },
            }
          );
        });
      }, main);

      return () => {
        ctx.revert();
      };
    };

    initializeScrollTrigger();
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        console.log("Timeout Completed");
        ScrollTrigger.refresh(); // Refresh ScrollTrigger after the timeout
      }, 1000); // Delay ScrollTrigger.refresh() by 5 seconds
    };

    // Check if the document has already loaded
    if (document.readyState === "complete") {
      // If loaded, immediately call the handler
      handleLoad();
    } else {
      // Otherwise, wait for the load event
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad); // Cleanup the event listener
    };
  }, []);

  return (
    <div ref={main}>
      <div className="bgs overflow-hidden">
        <ParentContainer
          containerStyle={"flex max-md:flex-wrap max-md:!pt-0"}
          containerHeight={"pt-0 pb-40"}
        >
          <div className="w-1/3 max-md:w-full">
            <img
              src={
                ApiUrl +
                data.data.attributes.Regen_Med_Image.data.attributes.url
              }
              alt={
                data.data.attributes.Regen_Med_Image.data.attributes
                  ?.alternativeText ?? ""
              }
              className={`w-full imgs ${
                isVisible1 ? "animate__animated animate__slideInLeft" : ""
              }  `}
            />
          </div>
          <div
            className={`flex w-2/3 justify-start flex-wrap pl-20 gap-5 py-20 content-center max-md:w-full max-md:pl-0 ${
              isVisible1 ? "animate__animated animate__slideInRight" : ""
            } `}
          >
            <Button
              Linkto={"stem-cells-sceince"}
              titleof={data.data.attributes.Regen_Med_Button_Text}
              CustomStyle={"!justify-start !px-0 boxInt"}
            />
            <h2 className="text-5xl font-bold text-left  w-full  max-md:text-2xl boxInt">
              {beforeDelimiter}
              <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
                {afterDelimiter}
              </span>
            </h2>
            <p className="w-full text-left text-xl opacity-80 max-md:text-base boxInt">
              {data.data.attributes.Regen_Med_Para}
            </p>
          </div>
        </ParentContainer>
      </div>
    </div>
  );
};

export default Introduction;
