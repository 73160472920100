import { BlocksRenderer } from "@strapi/blocks-react-renderer";

const RichTextRenderer = ({ content }) => {
  return (
    <BlocksRenderer
      content={content}
      blocks={{
        paragraph: ({ children }) => (
          <p className="mb-4 text-gray-600">{children}</p>
        ),
        heading: ({ level, children }) => {
          const HeaderTag = `h${level}`;
          return (
            <HeaderTag
              className={`mb-4 font-bold ${
                level === 1 ? "text-3xl" : level === 2 ? "text-2xl" : "text-xl"
              }`}
            >
              {children}
            </HeaderTag>
          );
        },
        list: ({ children, format }) =>
          format === "unordered" ? (
            <ul className="list-disc pl-6 mb-4">{children}</ul>
          ) : (
            <ol className="list-decimal pl-6 mb-4">{children}</ol>
          ),
        listItem: ({ children }) => <li className="mb-2">{children}</li>,
        quote: ({ children }) => (
          <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4">
            {children}
          </blockquote>
        ),
        code: ({ children }) => (
          <pre className="bg-gray-100 p-4 rounded-md overflow-x-auto my-4">
            <code>{children}</code>
          </pre>
        ),
        image: ({ image }) => (
          <img
            src={image.url}
            alt={image.alternativeText || ""}
            className="my-4 rounded-lg"
            width={image.width}
            height={image.height}
          />
        ),
      }}
      modifiers={{
        bold: ({ children }) => (
          <strong className="font-semibold">{children}</strong>
        ),
        italic: ({ children }) => <em className="italic">{children}</em>,
        underline: ({ children }) => <u className="underline">{children}</u>,
      }}
    />
  );
};

export default RichTextRenderer;
