import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import CountUp from "react-countup";
import Button from "../../components/Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";

gsap.registerPlugin(ScrollTrigger);

const About = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const [isNumbersss, setIsNumbersss] = useState(false);
  const [beforeDelimiter, afterDelimiter] =
    data.data.attributes.About_Section_Heading.split("||");

  const main = useRef();

  useEffect(() => {
    const initializeScrollTrigger = () => {
      const ctx = gsap.context(() => {
        const boxes = gsap.utils.toArray(".box110");
        boxes.forEach((box) => {
          gsap.fromTo(
            box,
            { y: 100, opacity: 0 },
            {
              y: 0,
              opacity: 1,
              scrollTrigger: {
                trigger: box,
                start: "bottom 100%",
                end: "top 70%",
                scrub: 1,
                //  markers: true,
              },
            }
          );
        });

        const circles = gsap.utils.toArray(".circle110");
        circles.forEach((circle) => {
          gsap.fromTo(
            circle,
            { scale: 0.5 },
            {
              scale: 1,

              scrollTrigger: {
                trigger: circle,
                start: "top 70%",
                end: "top 40%",
                onEnter: () => {
                  setIsCounting(true);
                },
                scrub: 1,
                //markers: true,
              },
            }
          );
        });

        const images = gsap.utils.toArray(".imgs");
        images.forEach((imgs) => {
          gsap.fromTo(
            imgs,
            { scale: 0.5 },
            {
              scale: 1,

              scrollTrigger: {
                trigger: imgs,
                start: "top 70%",
                end: "top 40%",
                scrub: 2,
                //  markers: true,
              },
            }
          );
        });

        const bgssss = gsap.utils.toArray(".bgs");
        bgssss.forEach((bgs) => {
          gsap.fromTo(
            bgs,
            { background: "linear-gradient(to right, white, white)" },
            {
              background: "linear-gradient(to right, #37424A, black)",
              scrollTrigger: {
                trigger: bgs,
                start: "bottom 50%",
                end: "top -50%",
                scrub: 1,
                //markers: true,
              },
            }
          );
        });
      }, main);

      return () => {
        ctx.revert();
      };
    };

    initializeScrollTrigger();
  }, []);

  useEffect(() => {
    const handleLoad = () => {
      setTimeout(() => {
        console.log("Timeout Completed");
        ScrollTrigger.refresh(); // Refresh ScrollTrigger after the timeout
      }, 1000); // Delay ScrollTrigger.refresh() by 5 seconds
    };

    // Check if the document has already loaded
    if (document.readyState === "complete") {
      // If loaded, immediately call the handler
      handleLoad();
    } else {
      // Otherwise, wait for the load event
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad); // Cleanup the event listener
    };
  }, []);

  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={
          "bg-gradient-to-r from-custom-gray to-black flex flex-wrap col justify-center overflow-x-hidden "
        }
        // containerStyle={
        //   "bgs flex flex-wrap col justify-center overflow-x-hidden "
        // }
        className=""
      >
        <div
          className={`flex justify-center flex-wrap gap-5 py-20 max-md:pt-0 box110 ${
            isVisible ? "animate__animated animate__fadeInUp" : ""
          }`}
        >
          <Button
            Linkto={"/ar/about"}
            titleof={data.data.attributes.About_Section_Button_Text}
            CustomStyle={"box"}
            white={true}
          />
          <h2 className="text-5xl font-bold text-center text-white w-1/2 max-md:w-full max-md:text-2xl mx-5 box">
            {beforeDelimiter}
            <span className="bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
              {afterDelimiter}
            </span>
          </h2>
          <p className="w-1/2 text-center text-xl opacity-80 font-bold text-white max-md:w-full max-md:text-base box">
            {data.data.attributes.About_Section_Para}
          </p>
        </div>
        <div
          className={`w-2/6 pb-10 max-md:w-5/6 ${
            isVisible
              ? "animate__animated animate__fadeInUp animate__delay-1s"
              : ""
          }`}
          // ref={main2}
        >
          <img
            src={
              ApiUrl +
              data.data.attributes.About_Section_Image_One.data.attributes.url
            }
            className="w-full imgs"
            alt={
              data.data.attributes.About_Section_Image_One.data.attributes
                .alternativeText
                ? data.data.attributes.About_Section_Image_One.data.attributes
                    .alternativeText
                : ""
            }
          />
        </div>
        <div
          className={`w-full flex py-20 max-md:flex-wrap  max-md:gap-10 max-md:py-0 ${
            isVisible
              ? "animate__animated animate__fadeInUp animate__delay-1s"
              : ""
          }`}
          // ref={main3}
        >
          <div
            className={`w-1/3 flex justify-start flex-wrap col max-md:w-full circle110 ${
              isNumbersss ? "animate__animated animate__fadeInTopRight" : ""
            }  `}
          >
            <h3 className="w-full text-center   text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
              {isCounting && (
                <CountUp
                  start={0}
                  end={data.data.attributes.About_Section_Counter_Number_One}
                  duration={5}
                />
              )}
            </h3>
            <p className="w-full text-center text-lg font-semibold text-white">
              {data.data.attributes.About_Section_Counter_Text_One}
            </p>
          </div>
          <div
            className={`w-1/3 circle110 flex justify-start flex-wrap col max-md:w-full number ${
              isNumbersss ? "animate__animated animate__fadeInDown" : ""
            }`}
          >
            <h3 className="w-full pb-5  text-center text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
              {/* {isCounting && (
                <CountUp start={0} end={3.1} duration={7} decimals={1} />
              )}
              M */}
              {data.data.attributes.About_Section_Counter_Number_Two}
            </h3>
            <p className="w-full text-center text-lg font-semibold text-white">
              {data.data.attributes.About_Section_Counter_Text_Two}
            </p>
          </div>
          <div
            className={`w-1/3 flex justify-start flex-wrap col max-md:w-full circle110 ${
              isNumbersss ? "animate__animated animate__fadeInTopLeft" : ""
            }`}
          >
            <h3 className="w-full   text-center text-5xl font-bold bg-gradient-to-br from-custom-primary to-custom-secondary text-transparent bg-clip-text">
              {isCounting && (
                <CountUp
                  start={0}
                  end={data.data.attributes.About_Section_Counter_Number_Three}
                  duration={5}
                />
              )}
            </h3>
            <p className="w-full text-center text-lg font-semibold text-white">
              {data.data.attributes.About_Section_Counter_Text_Three}
            </p>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default About;
