import React, { useState, useEffect } from "react";
import ParentContainer from "../../components/ParentContainer";

const TextScroll = () => {
  const [visibleText, setVisibleText] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const scrollPosition = window.scrollY;

      // Calculate the position of each text element
      const textPositions = [
        document.getElementById("text1").getBoundingClientRect().top,
        document.getElementById("text2").getBoundingClientRect().top,
        document.getElementById("text3").getBoundingClientRect().top,
      ];

      // Set the scroll threshold
      const scrollThreshold = 0.6; // Adjust as needed

      // Check if each text element reaches the scroll threshold
      for (let i = 0; i < textPositions.length; i++) {
        if (
          textPositions[i] < windowHeight * scrollThreshold &&
          textPositions[i] > -windowHeight * scrollThreshold
        ) {
          setVisibleText(i + 1);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ParentContainer>
      <div className="py-[50vh] flex flex-col justify-center items-center">
        <div
          id="text1"
          className={`transition-opacity duration-500 mb-60 ${
            visibleText === 1 ? "opacity-100" : "opacity-0"
          }`}
        >
          <h1 className="text-5xl font-bold text-center  w-full mx-5 max-md:mx-0">
            1 The Power of{" "}
            <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
              Newborn Stem Cells
            </span>
          </h1>
        </div>
        <div
          id="text2"
          className={`transition-opacity duration-500  mb-60 ${
            visibleText === 2 ? "opacity-100" : "opacity-0"
          }`}
        >
          <h1 className="text-5xl font-bold text-center  w-full mx-5  max-md:mx-0">
            2 The Power of{" "}
            <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
              Injury Recovery
            </span>
          </h1>
        </div>
        <div
          id="text3"
          className={`transition-opacity duration-500 ${
            visibleText === 3 ? "opacity-100" : "opacity-0"
          }`}
        >
          <h1 className="text-5xl font-bold text-center  w-full mx-5  max-md:mx-0">
            3 The Power of{" "}
            <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text ">
              Cord Blood
            </span>
          </h1>
        </div>
      </div>
    </ParentContainer>
  );
};

export default TextScroll;
