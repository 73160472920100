import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import assets from "../../assets/assets";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";

const SecondStep = ({ handleClickfrom, data, order }) => {
  const handleChange = (item) => {
    handleClickfrom(item);
  };
  const [quantity, setQuantity] = useState(1);
  const [months, setMonths] = useState(0);
  const [addon, setAddon] = useState(false);
  const [totalPrice, setTotalPrice] = useState(null);
  const [dueNow, setDueNow] = useState(null);
  const [coupon, setCoupon] = useState(null);
  const [dueMonthly, setDueMonthly] = useState(null);
  const [babyDiscount, setBabyDiscount] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("0");

  const [searchTerm, setSearchTerm] = useState("");

  const options = [
    { name: data[0].attributes.Title, link: "/ar/product/4" },
    { name: data[1].attributes.Title, link: "/ar/product/6" },
    { name: data[2].attributes.Title, link: "/ar/product/5" },
  ];

  const payments = [
    { name: "المبلغ الكامل", value: 0 },
    { name: "تمويل لمدة 3 أشهر", value: 3 },
    { name: "تمويل لمدة 6 أشهر", value: 6 },
    { name: "تمويل لمدة 9 أشهر", value: 9 },
    { name: "تمويل لمدة 12 شهرًا", value: 12 },
  ];

  const [selectedOption, setSelectedOption] = useState(
    data[0].attributes.Title
  );

  const handleOptionChange = (option, index) => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
    setSelectedProduct(index);
    console.log("selected product", index);
  };

  const [selectedPayment, setSelectedPayment] = useState(payments[0].value);
  const handlePaymentChange = (payment) => {
    setSelectedPayment(payment);
    console.log("selected Payment:", payment);
  };

  const handleQuantity = (value, add) => {
    if (add) {
      if (quantity <= 1) setQuantity(Number(quantity) + Number(value));
    } else {
      if (quantity >= 2) setQuantity(Number(quantity) - Number(value));
    }
    console.log("quantity", quantity);
  };

  const [couponCode, setCouponCode] = useState("");
  const [couponStatus, setCouponStatus] = useState("");
  const [coupondata, setCoupondata] = useState("");

  const handleInputChange = (event) => {
    setCouponCode(event.target.value);
  };

  const CouponHandler = async (query) => {
    if (!query) {
      return console.log("nothing");
    }
    console.log(query);
    try {
      const response = await axios.post(ApiUrl + "/api/coupon-check", {
        couponCodes: query,
      });

      if (response.data.Used === true) {
        setCoupondata(response.data);
      } else {
        setCouponStatus("Coupon not valid");
        setCoupondata(null);
      }
      return response.data;
    } catch (error) {
      setCoupondata(null);
      setCouponStatus("Not Valid");
      console.error(`Error searching FAQs with query ${query}:`, error);
      throw error;
    }
  };

  const handleButtonClick = async (event) => {
    event.preventDefault(); // Prevent form submission
    try {
      const data = await CouponHandler(couponCode);
      console.log("Coupon data:", data);
    } catch (error) {
      console.error("Error fetching coupon data:", error);
    }
  };

  useEffect(() => {
    let dueNows = 0;
    let dueMonthly = 0;
    let coupondiscount = 0;
    let babydiscount = 0;

    if (selectedPayment === 0) {
      dueMonthly = 0;
    }

    let totalPrice =
      data[selectedProduct].attributes.Enrollment_Fee +
      data[selectedProduct].attributes.Processing_Fee +
      data[selectedProduct].attributes.Storage_fee_30_years;

    if (selectedPayment > 0) {
      dueNows =
        data[selectedProduct].attributes.Enrollment_Fee +
        data[selectedProduct].attributes.Processing_Fee +
        95;
    }

    if (quantity > 1) {
      babydiscount =
        (data[selectedProduct].attributes.Enrollment_Fee +
          data[selectedProduct].attributes.Processing_Fee +
          data[selectedProduct].attributes.Storage_fee_30_years) *
        0.3;
    } else {
      babydiscount = null;
    }

    if (selectedProduct === 2) {
      babydiscount = null;
    }

    if (addon) {
      totalPrice += data[selectedProduct].attributes.Addon_Price;
    }

    setBabyDiscount(babydiscount);

    if (coupondata) {
      if (coupondata.Amount && coupondata.Coupon_Value === "Amount") {
        coupondiscount = coupondata.Amount;
        setCoupon(coupondiscount);
      } else if (
        coupondata.Percentage &&
        coupondata.Coupon_Value === "Percentage"
      ) {
        coupondiscount = totalPrice * (coupondata.Percentage / 100);
        setCoupon(coupondiscount);
      }
    }

    totalPrice -= coupondiscount;

    if (selectedPayment === 0) {
      if (quantity === 2) {
        setDueMonthly(null);
        setTotalPrice(totalPrice * 2 - babydiscount);
        setDueNow(totalPrice * 2 - babydiscount);
      } else {
        setDueMonthly(null);
        setTotalPrice(totalPrice);
        setDueNow(totalPrice);
      }
    } else {
      if (quantity === 2) {
        setDueNow(dueNows * 2 - 95);
        setTotalPrice(totalPrice * 2 + 95 - babydiscount);
        setDueMonthly(
          (totalPrice * 2 + 95 - babydiscount - (dueNows * 2 - 95)) /
            selectedPayment
        );
      } else {
        setDueNow(dueNows);
        setTotalPrice(totalPrice + 95);
        setDueMonthly((totalPrice + 95 - dueNows) / selectedPayment);
      }
    }
  }, [
    data,
    quantity,
    selectedProduct,
    addon,
    selectedPayment,
    coupondata,
    couponStatus,
  ]);

  useEffect(() => {
    order({
      quantity: quantity,
      selectedProduct: selectedProduct,
      addon: addon,
      selectedPayment: selectedPayment,
      coupondata: coupondata,
      duenow: dueNow,
      duemonthly: dueMonthly,
      totalPrice: totalPrice,
      couponCode: couponCode, // Corrected the typo from 'totalrice' to 'totalPrice'
    });
  }, [
    data,
    quantity,
    selectedProduct,
    addon,
    selectedPayment,
    coupondata,
    couponStatus,
    dueNow,
    dueMonthly,
    totalPrice,
  ]);

  const handleResetCoupon = () => {
    setCouponCode("");
    setCouponStatus("");
    setCoupon(null);
    setCoupondata(null);
  };

  return (
    <div className="bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg p-[1px]">
      <div className="p-8 bg-white rounded-lg flex flex-wrap max-md:p-4">
        <div className="w-4/6 max-lg:w-full">
          <div className="flex items-center gap-2 ">
            <div className="max-md:w-2/12">
              <svg
                width="36"
                height="36"
                viewBox="0 0 36 36"
                fill="none"
                className="max-md:w-full"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="18" cy="18" r="18" fill="#8884D5" />
                <path
                  d="M18.18 13.56C17.6333 13.56 17.1467 13.7133 16.72 14.02C16.3067 14.3267 15.84 14.8 15.32 15.44L13.16 13.7C13.5067 13.2333 13.8533 12.82 14.2 12.46C14.56 12.1 14.94 11.8 15.34 11.56C15.7533 11.3067 16.2067 11.12 16.7 11C17.1933 10.8667 17.7533 10.8 18.38 10.8C19.1267 10.8 19.7933 10.9067 20.38 11.12C20.98 11.32 21.4867 11.6133 21.9 12C22.3267 12.3733 22.6467 12.8267 22.86 13.36C23.0867 13.8933 23.2 14.4867 23.2 15.14C23.2 15.7267 23.12 16.2533 22.96 16.72C22.8133 17.1733 22.5867 17.6067 22.28 18.02C21.9867 18.4333 21.6067 18.8467 21.14 19.26C20.6733 19.66 20.1333 20.1 19.52 20.58L17.24 22.34H23.34V25H12.98V22.56L17.64 18.74C18.08 18.38 18.4533 18.06 18.76 17.78C19.0667 17.4867 19.3133 17.2133 19.5 16.96C19.7 16.6933 19.84 16.44 19.92 16.2C20.0133 15.9467 20.06 15.6733 20.06 15.38C20.06 14.78 19.8867 14.3267 19.54 14.02C19.1933 13.7133 18.74 13.56 18.18 13.56Z"
                  fill="white"
                />
              </svg>
            </div>
            <p className="text-lg font-semibold max-md:w-10/12 max-md:text-base">
              معلومات مجموعتك
            </p>
          </div>
          <p className="text-base pt-2 max-md:text-sm">
            لبدء العملية، يرجى إكمال النموذج أدناه.
          </p>
          <div className=" border-b pt-8 pb-8 ">
            <h4 className="text-lg font-semibold mb-2 max-md:text-sm">
              اختر مجموعتك:
            </h4>
            <div className="grid grid-cols-3 gap-4 w-full max-md:grid-cols-3 max-md:text-xs max-md:gap-1">
              {options.map((option, index) => (
                <div key={option.name} className="cursor-pointer">
                  <input
                    type="radio"
                    id={`option${option.name}`}
                    name="options"
                    className="sr-only"
                    checked={selectedOption === option.name}
                    onChange={() => {
                      handleOptionChange(option.name, index);
                    }}
                  />
                  <label htmlFor={`option${option.name}`}>
                    <div
                      className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 max-md:px-0 transition-colors ${
                        selectedOption === option.name
                          ? "border-custom-primary  bg-gradient-to-r from-custom-primary to-custom-secondary text-white font-semibold"
                          : "hover:border-custom-primary hover:text-custom-primary"
                      }`}
                    >
                      {option.name} <br />
                    </div>
                  </label>
                  {option.link && (
                    <a
                      href={option.link}
                      className={`font-normal text-center w-full inline-block`}
                    >
                      اطّلع على المزيد
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className=" flex mt-4 items-center justify-between py-4  rounded-lg max-md:flex-wrap">
            <div className="font-bold text-xl max-md:text-sm">
              <h4 className="text-lg font-semibold mb-2 max-md:text-sm">
                الكمية
              </h4>
              <p className=" font-normal text-base max-md:text-sm max-md:mb-4">
                عدد الأطفال المراد تخزين دم الحبل السري لهم: <br />
                خصم 50٪ على باقات CryoAdvanced و CryoUltimate للطفل الثاني
              </p>
            </div>
            <div className="flex items-center gap-2">
              <svg
                onClick={() => handleQuantity(1, false)}
                width="40"
                height="40"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.077 30.01H23.917V27.09H30.077V30.01Z"
                  fill="#37424A"
                />
                <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
              </svg>
              <p className="font-bold text-xl max-md:text-sm ">{quantity}</p>
              <svg
                onClick={() => handleQuantity(1, true)}
                width="40"
                height="40"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M25.605 26.33V22.73H28.445V26.33H32.065V29.09H28.445V32.69H25.605V29.09H21.985V26.33H25.605Z"
                  fill="#37424A"
                />
                <circle cx="27.5" cy="27.5" r="27" stroke="#37424A" />
              </svg>
            </div>
          </div>
          <div className=" border-b pt-8 pb-8">
            <h4 className="text-lg font-semibold mb-2 max-md:text-sm">
              الدفع:
            </h4>
            <div className="grid grid-cols-5 gap-4 w-full max-md:grid-cols-1">
              {payments.map((payment) => (
                <div key={payment.name} className="cursor-pointer">
                  <input
                    type="radio"
                    id={`payment${payment.name}`}
                    name="payment"
                    value={payment.value}
                    className="sr-only"
                    checked={selectedPayment === payment.value}
                    onChange={() => handlePaymentChange(payment.value)}
                  />
                  <label
                    className="max-md:text-sm"
                    htmlFor={`payment${payment.name}`}
                  >
                    <div
                      className={`border-2 border-gray-300 rounded-md text-center py-4 p-2 transition-colors ${
                        selectedPayment === payment.name
                          ? "border-custom-primary text-custom-primary"
                          : "hover:border-custom-primary hover:text-custom-primary"
                      }`}
                    >
                      {payment.name} <br />
                      {payment.link && (
                        <Link to={payment.link}>اطّلع على المزيد</Link>
                      )}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className=" border-b pt-8 pb-8">
            <h4 className="text-lg font-semibold mb-2 max-md:text-sm">
              حقيبة تخزين متعددة الحجرات:
            </h4>
            <div className="border rounded-lg p-8 grid grid-cols-8 gap-2">
              <p className="text-lg font-semibold col-span-6 max-md:col-span-8 max-md:text-sm">
                هل ترغب بإضافة حقيبة تخزين بأربعة حجرات بتكلفة 1000 درهم؟
              </p>
              <div className="col-span-1 flex gap-2 max-md:col-span-4">
                <input
                  type="radio"
                  name="chmber"
                  id="chmber-yes"
                  checked={addon === true}
                  onChange={() => setAddon(true)}
                />
                <label htmlFor="chmber-yes">نعم</label>
              </div>
              <div className="col-span-1 flex gap-2 max-md:col-span-4">
                <input
                  type="radio"
                  name="chmber"
                  id="chmber-no"
                  checked={addon === false}
                  onChange={() => setAddon(false)}
                />
                <label htmlFor="chmber-no">لا</label>
              </div>
              <p className="text-base col-span-8 max-md:text-sm">
                أضف حقيبة تخزين بأربعة حجرات متميزة مقابل 1000 درهم إضافية. توفر
                خمس حجرات منفصلة إمكانية حصول أفراد العائلة على ما يصل إلى أربعة
                علاجات بالخلايا الجذعية.
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/6 pr-12 max-lg:w-full max-lg:pr-0">
          <div className="border rounded-lg py-8 px-4">
            <h3 className="text-custom-primary text-xl font-semibold capitalize">
              ملخص الطلب
            </h3>
            <div className="flex flex-wrap border-b py-8 items-center justify-between">
              <img
                src={assets.checkoutProduct}
                alt={"منتج مجموعة سيل سيف"}
                className=" w-6/12 max-md:w-full"
              />
              <h3 className="w-5/12 text-xl font-semibold max-md:w-full">
                {selectedOption}
              </h3>
            </div>
            <div className="flex flex-col gap-2 py-4">
              <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                <h4 className="text-base max-md:w-full">رسوم الاشتراك</h4>
                <h4 className="text-base font-semibold max-md:w-full">
                  {quantity == 2
                    ? (
                        data[selectedProduct].attributes.Enrollment_Fee * 2
                      ).toLocaleString()
                    : data[
                        selectedProduct
                      ].attributes.Enrollment_Fee.toLocaleString()}{" "}
                  AED
                </h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                <h4 className="text-base  max-md:w-full">رسوم المعالجة</h4>
                <h4 className="text-base font-semibold  max-md:w-full">
                  {quantity == 2
                    ? (
                        data[selectedProduct].attributes.Processing_Fee * 2
                      ).toLocaleString()
                    : data[
                        selectedProduct
                      ].attributes.Processing_Fee.toLocaleString()}{" "}
                  AED
                </h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                <h4 className="text-base  max-md:w-full">
                  رسوم التخزين لمدة 30 عامًا
                </h4>
                <h4 className="text-base font-semibold  mx-md:w-full">
                  {quantity == 2
                    ? (
                        data[selectedProduct].attributes.Storage_fee_30_years *
                        2
                      ).toLocaleString()
                    : data[
                        selectedProduct
                      ].attributes.Storage_fee_30_years.toLocaleString()}{" "}
                  AED
                </h4>{" "}
              </div>
              {addon && (
                <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                  <h4 className="text-base  max-md:w-full">خيار إضافي</h4>
                  <h4 className="text-base font-semibold  max-md:w-full">
                    {(
                      data[selectedProduct].attributes.Addon_Price * quantity
                    ).toLocaleString()}{" "}
                    AED
                  </h4>{" "}
                </div>
              )}
              {coupon && (
                <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                  <h4 className="text-base  max-md:w-full">خصم القسيمة</h4>
                  <h4 className="text-base font-semibold  max-md:w-full">
                    - {coupon.toLocaleString()} AED
                  </h4>{" "}
                </div>
              )}
              <div className="flex items-center justify-between w-full py-4 text-base">
                <input
                  type="text"
                  className="w-full border rounded-lg py-2 px-2"
                  placeholder="تطبيق الرمز"
                  value={couponCode}
                  onChange={handleInputChange}
                  disabled={!!coupondata} // تعطيل الحقل عند استخدام قسيمة بالفعل
                />
                {!coupondata ? (
                  // Apply Button
                  <button
                    type="button"
                    className="inline w-2/12 flex justify-end mr-2"
                    onClick={handleButtonClick}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                        stroke="url(#paint0_linear_590_10279)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22 22L20 20"
                        stroke="url(#paint1_linear_590_10279)"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_590_10279"
                          x1="0.198276"
                          y1="4.11111"
                          x2="24.3107"
                          y2="5.81344"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8884D5" />
                          <stop offset="1" stopColor="#CF0072" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_590_10279"
                          x1="19.8103"
                          y1="20.2222"
                          x2="22.3485"
                          y2="20.4014"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#8884D5" />
                          <stop offset="1" stopColor="#CF0072" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                ) : (
                  // Reset Button
                  <button
                    type="button"
                    className="inline w-2/12 flex justify-end bg-gray-200 hover:bg-gray-300 text-gray-700 rounded-lg py-2 px-4"
                    onClick={handleResetCoupon}
                  >
                    <svg
                      data-name="Layer 1"
                      id="Layer_1"
                      viewBox="0 0 512 512"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M64,256H34A222,222,0,0,1,430,118.15V85h30V190H355V160h67.27A192.21,192.21,0,0,0,256,64C150.13,64,64,150.13,64,256Zm384,0c0,105.87-86.13,192-192,192A192.21,192.21,0,0,1,89.73,352H157V322H52V427H82V393.85A222,222,0,0,0,478,256Z" />
                    </svg>
                  </button>
                )}
              </div>

              {couponStatus && (
                <p className=" ">
                  {couponStatus} {coupondata && coupondata.Amount}
                </p>
              )}
              {babyDiscount && (
                <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                  <h4 className="text-base  max-md:w-full">خصم لطفلين</h4>
                  <h4 className="text-base font-semibold  max-md:w-full">
                    - {babyDiscount.toLocaleString()} AED
                  </h4>{" "}
                </div>
              )}
              {selectedPayment > 0 && (
                <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                  <h4 className="text-base  max-md:w-full">
                    رسوم التقسيط لمرة واحدة
                  </h4>
                  <h4 className="text-base font-semibold  max-md:w-full">
                    95 AED
                  </h4>{" "}
                </div>
              )}
              <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                <h4 className="text-base  max-md:w-full">السعر الإجمالي</h4>
                <h4 className="text-base font-semibold  max-md:w-full">
                  {totalPrice && totalPrice.toLocaleString()} AED
                </h4>{" "}
              </div>
              <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                <h4 className="text-base  max-md:w-full">
                  المبلغ المستحق الآن
                </h4>
                <h4 className="text-base font-semibold  max-md:w-full">
                  {dueNow && dueNow.toLocaleString()} AED
                </h4>{" "}
              </div>
              {dueMonthly && (
                <div className="flex flex-wrap  items-center justify-between max-md:flex-wrap max-md:gap-2">
                  <h4 className="text-base  max-md:w-full">
                    المبلغ الشهري المستحق
                  </h4>
                  <h4 className="text-base font-semibold  max-md:w-full">
                    {dueMonthly.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    AED
                  </h4>{" "}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-5 pt-8">
          <div
            onClick={() => handleChange("thirdStep")}
            className=" bg-gradient-to-r from-custom-primary to-custom-secondary transition-all text-white text-base font-semibold px-8 py-2 rounded-3xl"
          >
            التالي
          </div>
          <div
            onClick={() => handleChange("firstStep")}
            className="bg-gradient-to-r from-custom-primary to-custom-secondary transition-all p-[1px] group hover:text-white text-base  rounded-3xl "
          >
            <div className="bg-white group-hover:bg-transparent px-8 py-2 rounded-3xl ">
              <span className="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text group-hover:text-white transition-all font-semibold">
                السابق
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondStep;
