import React, { useState } from "react";
import axios from "axios";
import assets from "../../assets/assets";
import { ApiUrl } from "../../components/ApiUrl";

const Form = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [buttonText, setButtonText] = useState("Submit");

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    console.log("Selected Option:", option);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    setButtonText("Sending..."); // Update button text

    const emailData = {
      to: "marketing@cellsave.com, rima.kobeissi@cellsave.com, catia.shamia@cellsave.com, Nadya.abbas@cellsave.com",
      subject: "Referral Form Submission",
      text: "You have received a new referral submission.",
      html: `
      <h4>About You:</h4>
      <p><strong>First Name:</strong> ${event.target["first-Name"].value}</p>
      <p><strong>Last Name:</strong> ${event.target["last-Name"].value}</p>
      <p><strong>Phone Number:</strong> ${event.target["phone-number"].value}</p>
      <p><strong>Email Address:</strong> ${event.target.email.value}</p>
      <p><strong>Address:</strong> ${event.target.address.value}</p>
      
      <h4>About Your Friend:</h4>
      <p><strong>First Name:</strong> ${event.target["friend-first-Name"].value}</p>
      <p><strong>Last Name:</strong> ${event.target["friend-last-Name"].value}</p>
      <p><strong>Phone Number:</strong> ${event.target["friend-phone-number"].value}</p>
      <p><strong>Email Address:</strong> ${event.target["friend-email"].value}</p>
      <p><strong>Approximate Due Date:</strong> ${event.target["friend-date"].value}</p>
      
      <h4>Message:</h4>
      <p>${event.target.message.value}</p>
    `,
    };

    // Prepare Salesforce data
    const saleforceData = {
      FirstName: event.target["first-Name"].value,
      LastName: event.target["last-Name"].value,
      Email: event.target.email.value,
      MobilePhone: event.target["phone-number"].value,
      Origin_Program__c: "Online Referral Form",
      Lead_Quality__c: "Hot",
      AttendingDoctor__c: "a00f400000XCppCAAT",
      Hospital_Clinics__c: "a01f400000KF5VMAA1",
      Program_Running__c: "Website",
      Origin_Hospital__c: "a01f400000KF5VMAA1",
      Origin_Doctor__c: "a00f400000XCppCAAT",
    };

    try {
      // Send email and Salesforce data in parallel
      const [emailResponse, saleforceResponse] = await Promise.all([
        axios.post(ApiUrl + "/api/email/send", emailData),
        axios.post(ApiUrl + "/api/salesforce", saleforceData),
      ]);

      console.log("Email sent successfully:", emailResponse.data);
      console.log("Salesforce data sent successfully:", saleforceResponse.data);

      setButtonText("Sent Successfully"); // Update button text on success
    } catch (error) {
      console.error(
        "Error processing submission:",
        error.response ? error.response.data : error.message
      );
      setButtonText("Error! Try Again"); // Update button text in case of error
    } finally {
      setTimeout(() => {
        setButtonText("Submit"); // Reset button text after a delay
      }, 3000);
    }
  };

  return (
    <div className="flex flex-wrap p-8 bg-white max-lg:px-0">
      <div className="border rounded-lg p-8 pt-2 w-full">
        <form onSubmit={handleSubmit} className="">
          <div className="pt-2 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4 max-md:col-span-4">
              About you*
            </h4>
            <div className="col-span-4 grid grid-cols-2 gap-4">
              <div className="max-md:col-span-4">
                <label htmlFor="first-Name" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="first-Name"
                  id="first-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                />
              </div>

              <div className="max-md:col-span-4">
                <label htmlFor="last-Name" className="block mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  name="last-Name"
                  id="last-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                />
              </div>
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="phone-number" className="block mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="phone-number"
                id="phone-number"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
            <div className="col-span-4">
              <label htmlFor="address" className="block mb-1">
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
          </div>
          <div className="pt-10 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4 capitalize">
              About your friend*
            </h4>
            <div className="col-span-4 grid grid-cols-2 gap-4">
              <div className="max-md:col-span-4">
                <label htmlFor="friend-first-Name" className="block mb-1">
                  First Name
                </label>
                <input
                  type="text"
                  name="friend-first-Name"
                  id="friend-first-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                />
              </div>

              <div className="max-md:col-span-4">
                <label htmlFor="friend-last-Name" className="block mb-1">
                  Last Name
                </label>
                <input
                  type="text"
                  name="friend-last-Name"
                  id="friend-last-Name"
                  className="border border-gray-300 rounded-md px-3 py-2 w-full"
                  required
                />
              </div>
            </div>

            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="friend-phone-number" className="block mb-1">
                Phone Number
              </label>
              <input
                type="tel"
                name="friend-phone-number"
                id="friend-phone-number"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="friend-email" className="block mb-1">
                Email Address
              </label>
              <input
                type="email"
                name="friend-email"
                id="friend-email"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
            <div className="col-span-2 max-md:col-span-4">
              <label htmlFor="friend-date" className="block mb-1">
                Approximate Due Date*
              </label>
              <input
                type="date"
                name="friend-date"
                id="friend-date"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
          </div>
          <div className="pt-10 border-b pb-8 grid grid-cols-4 gap-4">
            <h4 className="text-lg font-semibold mb-2 col-span-4 capitalize">
              Message
            </h4>

            <div className="col-span-4">
              <label htmlFor="message" className="block mb-1">
                Message
              </label>
              <textarea
                rows={5}
                name="message"
                id="message"
                className="border border-gray-300 rounded-md px-3 py-2 w-full"
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
          >
            {buttonText}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Form;
