import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "./ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import { Link, useLocation } from "react-router-dom";
import Button from "./Button";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const MainValuable = ({
  HeadingBlack,
  HeadingBlue,
  HeadingPara,
  isButton,
  ValueList,
  width,
  fontSizesH,
}) => {
  const BulletIcon = (
    <svg
      width="100%"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="30"
        height="30"
        rx="15"
        fill="url(#paint0_linear_590_6015)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_590_6015"
          x1="-2.84483"
          y1="3.33333"
          x2="35.2274"
          y2="6.02122"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#8884D5" />
          <stop offset="1" stop-color="#CF0072" />
        </linearGradient>
      </defs>
    </svg>
  );

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const location = useLocation();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, opacity: 0 },
          {
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: box,
              start: "bottom 60%",
              end: "top 60%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle2");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 150, y: 200, opacity: 0 },
          {
            x: 0,
            y: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 100%",
              end: "top 70%",
              scrub: 4,
              //  markers: true,
            },
          }
        );
      });

      const box2s = gsap.utils.toArray(".box2");
      box2s.forEach((boxs2) => {
        gsap.fromTo(
          boxs2,
          { x: 200, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            scrollTrigger: {
              trigger: boxs2,
              start: "bottom 60%",
              end: "top 47.5%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });
    }, main);

    return () => {
      ctx.revert();
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, [location]);

  return (
    <ParentContainer
      containerStyle={`flex items-start max-md:flex-wrap max-md:gap-5 ${width}  `}
    >
      <div
        id="sticky-heading"
        className="sticky w-2/4 top-2/4 max-md:w-full max-md:relative max-md:mb-5 max-md:top-0"
      >
        <h2
          className={`text-5xl font-bold text-left rtl:text-right pb-4 max-md:text-2xl ${fontSizesH}`}
        >
          {HeadingBlack}{" "}
          <span className="text-custom-primary">{HeadingBlue}</span>
        </h2>
        <p className=" text-base max-md:text-sm">{HeadingPara}</p>
        {isButton ? (
          <Button
            Linkto={"/stem-cells-sceince#medTabs"}
            titleof={"Read More"}
            CustomStyle={"!items-center !px-0  !justify-start !pt-5"}
          />
        ) : (
          ""
        )}
      </div>

      <div
        ref={main}
        className={`w-1/2 max-md:w-full overflow-hidden ${
          isVisible ? "animate__animated animate__fadeInRight" : ""
        }`}
      >
        {ValueList.map((value, index) => (
          <div
            key={index}
            className="p-5 pb-6 flex justify-between items-center border-b box2 border-[#EAECF0]"
          >
            <div id="icon" className="w-1/12">
              {BulletIcon}
            </div>
            <p
              className="text-lg font-semibold w-10/12 max-md:text-sm "
              dangerouslySetInnerHTML={{ __html: value }}
            ></p>
          </div>
        ))}
      </div>
    </ParentContainer>
  );
};

export default MainValuable;
