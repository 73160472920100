import React, { useEffect, useState } from "react";
import Packages from "../components/Packages";
import HeroSection from "./ServiceAndPricing/HeroSection";
import CellSaveKit from "./ServiceAndPricing/CellSaveKit";
import StemCellBanking from "./ServiceAndPricing/StemCellBanking";
import Sources from "./SourceOfStem/Sources";
import LifeTimeBanner from "../components/LifeTimeBanner";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import { Helmet } from "react-helmet";

const ServicesAndPricingPage = () => {
  const [homePageData, setHomePageData] = useState(null);
  const [sourceData, setSourceData] = useState(null);
  const [serviceData, setServiceData] = useState(null);
  const [loadingHomePage, setLoadingHomePage] = useState(true);
  const [loadingSource, setLoadingSource] = useState(true);
  const [loadingService, setLoadingService] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/home-page?populate=Hero_Section_Background_Video,Partners,Partners.Image_One,Partners.Image_Two,Partners.Image_Three,Partners.Image_Four,Partners.Image_Five,CryoAdvanced,CryoUltimate,CryoInfinite,Regen_Med_Image,About_Section_Image_One,Safe_Section_Image_One,Safe_Section_Image_Two,Safe_Section_Image_Three,Featured.Image_One,Featured.Image_Two,Featured.Image_Three,Featured.Image_Four,Featured.Image_Five"
        );
        setHomePageData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setError(true);
      } finally {
        setLoadingHomePage(false);
      }
    };

    const fetchSourceData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/sources-of-stem-cell?populate=Hero_Section,Hero_Section.Video,Faqs,Faqs.Points,Transformative_Image"
        );

        setSourceData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching source data:", error);
        setError(true);
      } finally {
        setLoadingSource(false);
      }
    };

    const fetchServiceData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/services-and-pricing?populate=Points,Kit_Image,Banking_Image,seo,seo.keywords"
        );

        setServiceData(response.data);
        console.log("Service Data", response.data);
      } catch (error) {
        console.error("Error fetching service data:", error);
        setError(true);
      } finally {
        setLoadingService(false);
      }
    };

    fetchSourceData();
    fetchHomePageData();
    fetchServiceData();
  }, []);

  if (loadingHomePage || loadingSource || loadingService) {
    return <div className="h-[150vh]"></div>;
  }

  if (error || !homePageData || !sourceData || !serviceData) {
    return <div>Error loading data.</div>;
  }

  return (
    <div>
      {/* <Helmet>
        <title>{serviceData?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={serviceData?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            serviceData?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet> */}
      <HeroSection data={serviceData.data.attributes} />
      <Packages data={homePageData.data.attributes} />
      <CellSaveKit data={serviceData.data.attributes} />
      <StemCellBanking data={serviceData.data.attributes} />
      <Sources
        data={sourceData.data.attributes}
        title={"Sources of || Stem Cells"}
      />
      <LifeTimeBanner
        Heading={serviceData.data.attributes.Banner_Text}
        ButtonText={"Gift Card"}
        Linkto={"/gift-registry"}
      />
    </div>
  );
};

export default ServicesAndPricingPage;
