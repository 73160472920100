import React, { useEffect, useState } from "react";
import ResourcesMiniNav from "../components/ResourcesMiniNav";
import BlogIndex from "./Blog/BlogIndex";
import BlogListing from "../components/BlogListing";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import NewsListing from "../components/NewsListing";

const News = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + "/api/blogs?populate=*&pagination[pageSize]=99&locale=ar"
        );

        setSource(response.data);
        console.log("news updated", response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  const blogData = source.data.filter(
    (blog) =>
      blog.attributes?.blog_category?.data?.attributes?.Category === "الأخبار "
  );

  console.log("news", blogData);

  return (
    <div dir="rtl">
      <ResourcesMiniNav />
      <NewsListing data={blogData} />
    </div>
  );
};

export default News;
