import React, { useState } from "react";
import assets from "../../assets/assets";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import { ApiUrl } from "../../components/ApiUrl";

const HeroSection = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSvg, setIsSvg] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const handleVideoLoad = () => {
    setVideoLoaded(true);
  };
  return (
    <div className="relative z-0 h-[90vh] flex justify-center items-end pb-40 max-lg:pb-56">
      {/* Video with thumbnail */}
      {!videoLoaded && (
        <img
          src={
            ApiUrl +
            data.data.attributes.Hero_Section_Thumbnail_Image.data.attributes
              .url
          }
          alt={
            data?.data?.attributes?.Hero_Section_Thumbnail_Image?.data
              ?.attributes?.alternativeText ?? ""
          }
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
      )}

      <video
        src={
          ApiUrl +
          data.data.attributes.Hero_Section_Background_Video.data[0].attributes
            .url
        }
        alt={
          data?.data?.attributes?.Hero_Section_Background_Video?.data?.[0]
            ?.attributes?.alternativeText ?? ""
        }
        autoPlay
        muted
        loop
        playsInline
        onLoadedData={handleVideoLoad}
        className={`absolute top-0 left-0 w-full h-full object-cover ${
          videoLoaded ? "block" : "hidden"
        }`}
      ></video>
      <div className=" absolute top-0 left-0 w-full h-full object-cover bg-[#0000005c]"></div>
      <Waypoint onEnter={handleEnter}>
        <div
          className={`flex justify-center flex-col items-center gap-5   text-justify ${
            isVisible ? "animate__animated animate__fadeInUp" : ""
          }`}
        >
          <h1 className="text-5xl font-bold text-left rtl:text-right  text-white max-md:text-2xl max-md:text-center max-md:px-20">
            {data.data.attributes.Hero_Section_Heading_Part_One}
            <br /> {data.data.attributes.Hero_Section_Heading_Part_Two}
            <span class="bg-gradient-to-r from-custom-primary to-custom-secondary text-transparent bg-clip-text">
              <br className="hidden max-md:block" />{" "}
              {data.data.attributes.Hero_Section_Heading_Part_Gradient}
            </span>
            <h4 className="text-2xl font-bold text-right pt-4  text-white max-md:text-right max-md:text-base max-md:font-normal">
              {data.data.attributes.Hero_Section_Sub_Heading}
            </h4>
          </h1>

          <a
            href="/ar/checkout"
            className={`flex items-center gap-5 ${
              isVisible
                ? "animate__animated animate__fadeInUp animate__delay-1s"
                : ""
            } `}
          >
            {" "}
            <a
              href=" "
              onMouseEnter={() => setIsSvg(true)}
              onMouseLeave={() => setIsSvg(false)}
              className="max-md:hidden"
            >
              <svg
                width="45"
                height="45"
                viewBox="0 0 55 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="27.5"
                  cy="27.5"
                  r="27"
                  stroke="url(#paint0_linear_590_3732)"
                  fill={isSvg ? "white" : ""}
                />
                <path
                  d="M31.671 28.9043L27.7222 32.8531L23.7734 28.9043"
                  stroke="url(#paint1_linear_590_3732)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M27.6777 23.4199L27.6777 32.5274"
                  stroke="url(#paint2_linear_590_3732)"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_590_3732"
                    x1="-5.21552"
                    y1="6.11111"
                    x2="64.5835"
                    y2="11.0389"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear_590_3732"
                    x1="23.0245"
                    y1="29.343"
                    x2="32.9001"
                    y2="30.7375"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                  <linearGradient
                    id="paint2_linear_590_3732"
                    x1="27.5829"
                    y1="24.4319"
                    x2="28.8582"
                    y2="24.4418"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#8884D5" />
                    <stop offset="1" stop-color="#CF0072" />
                  </linearGradient>
                </defs>
              </svg>
            </a>
            <a
              onMouseEnter={(e) =>
                e.currentTarget.classList.add("bg-gradient-hover")
              }
              onMouseLeave={(e) =>
                e.currentTarget.classList.remove("bg-gradient-hover")
              }
              className=" transition-all max-md:text-sm max-md:font-semibold flex gap-2 hover:gap-5 items-center hover:border-white hover:border bg-gradient-to-br from-custom-primary to-custom-secondary text-white px-5 py-3 rounded-3xl font-semibold"
            >
              {data.data.attributes.Hero_Section_Button_Text}

              <svg
                width="46"
                height="16"
                viewBox="0 0 46 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g transform="scale(-1,1) translate(-46,0)">
                  <path
                    d="M1.79566 6.83825C1.24339 6.83405 0.792276 7.27834 0.788072 7.83061C0.783869 8.38288 1.22816 8.83399 1.78043 8.8382L1.79566 6.83825ZM44.9149 8.87364C45.3084 8.4861 45.3132 7.85296 44.9257 7.45947L38.6104 1.04725C38.2228 0.653767 37.5897 0.648946 37.1962 1.03649C36.8027 1.42403 36.7979 2.05717 37.1854 2.45066L42.7991 8.15041L37.0993 13.764C36.7058 14.1516 36.701 14.7847 37.0885 15.1782C37.4761 15.5717 38.1092 15.5765 38.5027 15.189L44.9149 8.87364ZM1.78043 8.8382L44.2056 9.16115L44.2208 7.1612L1.79566 6.83825L1.78043 8.8382Z"
                    fill="white"
                  />
                </g>
              </svg>
            </a>
          </a>
        </div>
      </Waypoint>
    </div>
  );
};

export default HeroSection;
