import "./App.css";
import MainLayout from "./components/MainLayout";

// English Pages
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import StemCellsSciencePage from "./pages/StemCellsSciencePage";
import RegenerativeMedicine from "./pages/RegenerativeMedicine";
import SourcesOfStem from "./pages/SourcesOfStem";
import CordBlood from "./pages/CordBlood";
import CordTissue from "./pages/CordTissue";
import PlacentaTissue from "./pages/PlacentaTissue";
import CordVessel from "./pages/CordVessel";
import AmnionTissue from "./pages/AmnionTissue";
import ServicesAndPricingPage from "./pages/ServicesAndPricingPage";
import GiftRegistry from "./pages/GiftRegistry";
import BlogPage from "./pages/BlogPage";
import BlogSingle from "./pages/BlogSingle";
import News from "./pages/News";
import FeaturedMoms from "./pages/FeaturedMoms";
import FeaturedMomsSingle from "./pages/FeaturedMomsSingle";
import FAQs from "./pages/FAQs";
import Contact from "./pages/Contact";
import ProfileHealthHistory from "./pages/ProfileHealthHistory";
import ProfileOrder from "./pages/ProfileOrder";
import ProfileDocs from "./pages/ProfileDocs";
import ProfileReferrals from "./pages/ProfileReferrals";
import ProductPage from "./pages/ProductPage";
import OrderNow from "./pages/OrderNow";
import RefundPolicy from "./pages/RefundPolicy";
import TermsCondition from "./pages/TermsCondition";
import PrivacyPolicy from "./pages/PrivacyPolicy";

// Arabic Pages
import HomePageAR from "./pages-ar/HomePage";
import AboutPageAR from "./pages-ar/AboutPage";
import StemCellsSciencePageAR from "./pages-ar/StemCellsSciencePage";
import RegenerativeMedicineAR from "./pages-ar/RegenerativeMedicine";
import SourcesOfStemAR from "./pages-ar/SourcesOfStem";
import CordBloodAR from "./pages-ar/CordBlood";
import CordTissueAR from "./pages-ar/CordTissue";
import PlacentaTissueAR from "./pages-ar/PlacentaTissue";
import CordVesselAR from "./pages-ar/CordVessel";
import AmnionTissueAR from "./pages-ar/AmnionTissue";
import ServicesAndPricingPageAR from "./pages-ar/ServicesAndPricingPage";
import GiftRegistryAR from "./pages-ar/GiftRegistry";
import BlogPageAR from "./pages-ar/BlogPage";
import BlogSingleAR from "./pages-ar/BlogSingle";
import NewsAR from "./pages-ar/News";
import FeaturedMomsAR from "./pages-ar/FeaturedMoms";
import FeaturedMomsSingleAR from "./pages-ar/FeaturedMomsSingle";
import FAQsAR from "./pages-ar/FAQs";
import ContactAR from "./pages-ar/Contact";
import ProfileHealthHistoryAR from "./pages-ar/ProfileHealthHistory";
import ProfileOrderAR from "./pages-ar/ProfileOrder";
import ProfileDocsAR from "./pages-ar/ProfileDocs";
import ProfileReferralsAR from "./pages-ar/ProfileReferrals";
import ProductPageAR from "./pages-ar/ProductPage";
import OrderNowAR from "./pages-ar/OrderNow";
import RefundPolicyAR from "./pages-ar/RefundPolicy";
import TermsConditionAR from "./pages-ar/TermsCondition";
import PrivacyPolicyAR from "./pages-ar/PrivacyPolicy";

// Common Components
import ScrollToTop from "./components/ScrollToTop";
import PrivateRoute from "./components/PrivateRoute";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { useEffect } from "react";

function ArabicFontHandler() {
  const location = useLocation(); // Get the current URL

  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      document.documentElement.classList.add("!font-arabic");
      document.documentElement.setAttribute("dir", "rtl");
    } else {
      document.documentElement.classList.add("!font-custom");
      document.documentElement.setAttribute("dir", "ltr");
    }
  }, [location.pathname]);

  return null; // This component does not render anything
}

function App() {
  return (
    <BrowserRouter>
      <ArabicFontHandler />
      <ScrollToTop />
      <Routes>
        {/* Default Routes (English) */}
        <Route path="/" element={<MainLayout />}>
          <Route
            index
            element={<HomePage />} // Redirect based on language
          />
          <Route path="about" element={<AboutPage />} />
          <Route path="stem-cells-sceince" element={<StemCellsSciencePage />} />
          <Route
            path="regenerative-medicine"
            element={<RegenerativeMedicine />}
          />
          <Route path="sources-of-stem-cell" element={<SourcesOfStem />} />
          <Route path="cord-blood" element={<CordBlood />} />
          <Route path="cord-tissue" element={<CordTissue />} />
          <Route path="placenta-tissue" element={<PlacentaTissue />} />
          <Route path="cord-vessel" element={<CordVessel />} />
          <Route path="amnion-tissue" element={<AmnionTissue />} />
          <Route path="service-pricing" element={<ServicesAndPricingPage />} />
          <Route path="gift-registry" element={<GiftRegistry />} />
          <Route path="blog" element={<BlogPage />} />
          <Route path="blog-single/:id" element={<BlogSingle />} />
          <Route path="news" element={<News />} />
          <Route path="featured-moms" element={<FeaturedMoms />} />
          <Route
            path="featured-moms-single/:id"
            element={<FeaturedMomsSingle />}
          />
          <Route path="faqs" element={<FAQs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="refund-policy" element={<RefundPolicy />} />
          <Route path="terms-condition" element={<TermsCondition />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />

          {/* Profile Routes (Private) */}
          <Route
            path="profile/maternal-history"
            element={<PrivateRoute element={<ProfileHealthHistory />} />}
          />
          <Route
            path="profile/order-history"
            element={<PrivateRoute element={<ProfileOrder />} />}
          />
          <Route
            path="profile/documents"
            element={<PrivateRoute element={<ProfileDocs />} />}
          />
          <Route
            path="profile/refer"
            element={<PrivateRoute element={<ProfileReferrals />} />}
          />

          {/* Product/Checkout */}
          <Route path="product/:id" element={<ProductPage />} />
          <Route path="checkout" element={<OrderNow />} />

          {/* 404 / catch-all */}
          <Route path="*" element={<HomePage />} />
        </Route>

        {/* Arabic Routes */}
        <Route path="/ar" element={<MainLayout />}>
          <Route index element={<HomePageAR />} />
          <Route path="about" element={<AboutPageAR />} />
          <Route
            path="stem-cells-sceince"
            element={<StemCellsSciencePageAR />}
          />
          <Route
            path="regenerative-medicine"
            element={<RegenerativeMedicineAR />}
          />
          <Route path="sources-of-stem-cell" element={<SourcesOfStemAR />} />
          <Route path="cord-blood" element={<CordBloodAR />} />
          <Route path="cord-tissue" element={<CordTissueAR />} />
          <Route path="placenta-tissue" element={<PlacentaTissueAR />} />
          <Route path="cord-vessel" element={<CordVesselAR />} />
          <Route path="amnion-tissue" element={<AmnionTissueAR />} />
          <Route
            path="service-pricing"
            element={<ServicesAndPricingPageAR />}
          />
          <Route path="gift-registry" element={<GiftRegistryAR />} />
          <Route path="blog" element={<BlogPageAR />} />
          <Route path="blog-single/:id" element={<BlogSingleAR />} />
          <Route path="news" element={<NewsAR />} />
          <Route path="featured-moms" element={<FeaturedMomsAR />} />
          <Route
            path="featured-moms-single/:id"
            element={<FeaturedMomsSingleAR />}
          />
          <Route path="faqs" element={<FAQsAR />} />
          <Route path="contact" element={<ContactAR />} />
          <Route path="refund-policy" element={<RefundPolicyAR />} />
          <Route path="terms-condition" element={<TermsConditionAR />} />
          <Route path="privacy-policy" element={<PrivacyPolicyAR />} />

          {/* Profile Routes (Arabic / Private) */}
          <Route
            path="profile/maternal-history"
            element={<PrivateRoute element={<ProfileHealthHistoryAR />} />}
          />
          <Route
            path="profile/order-history"
            element={<PrivateRoute element={<ProfileOrderAR />} />}
          />
          <Route
            path="profile/documents"
            element={<PrivateRoute element={<ProfileDocsAR />} />}
          />
          <Route
            path="profile/refer"
            element={<PrivateRoute element={<ProfileReferralsAR />} />}
          />

          {/* Product/Checkout in Arabic */}
          <Route path="product/:id" element={<ProductPageAR />} />
          <Route path="checkout" element={<OrderNowAR />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
