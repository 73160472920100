import React, { useState, useEffect } from "react";
import { search } from "../components/searchUtils";
import { buildSearchIndex } from "../components/searchIndex";

const SearchComponent = ({ setIsSearch }) => {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [searchIndex, setSearchIndex] = useState([]);

  useEffect(() => {
    const initializeIndex = async () => {
      const index = await buildSearchIndex();
      setSearchIndex(index);
    };

    initializeIndex();
  }, []);

  const handleSearch = (event) => {
    const q = event.target.value;
    setQuery(q);
    if (q) {
      const searchResults = search(q, searchIndex);
      setResults(searchResults);
    } else {
      setResults([]);
    }
    console.log("Current query:", q);
    console.log("Current results:", results);
  };

  return (
    <div className="fixed bg-black z-50 w-full h-screen  pt-20 flex-wrap flex justify-center  ">
      <div className="w-2/5   gap-0">
        <input
          type="text"
          value={query}
          onChange={handleSearch}
          placeholder="Search..."
          className="text-white bg-black border-b border-white w-full outline-0 text-2xl"
        />

        <ul className="w-full">
          {results.slice(0, 10).map((result, index) => (
            <li className="text-white" key={index}>
              <a href={result.url}>{result.name}</a>
            </li>
          ))}
        </ul>
      </div>
      <div
        className=" absolute left-5 top-5 cursor-pointer"
        onClick={() => setIsSearch(false)}
      >
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M18.3398 29.6598L29.6598 18.3398"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M29.6598 29.6598L18.3398 18.3398"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default SearchComponent;
