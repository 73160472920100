import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Button from "../../components/Button";
import { ApiUrl } from "../../components/ApiUrl";

gsap.registerPlugin(ScrollTrigger);

const Power = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };
  const bulletIcon = (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="8" stroke="white" stroke-width="4" />
    </svg>
  );
  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main} className="overflow-hidden">
      <ParentContainer
        containerStyle={
          "bg-gradient-to-r  from-custom-primary to-custom-secondary text-white  flex flex-wrap items-center  z-40 relative"
        }
      >
        <h3 className=" text-4xl w-2/3 font-bold text-white pb-5 box max-md:text-2xl max-md:w-full">
          {data.Transformative_Heading}
        </h3>
        <div className="w-1/3 cricle max-md:w-full">
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={
              "!w-full !px-0 !justify-end circle max-md:!justify-start"
            }
            white={true}
          />
        </div>
        <div className="flex flex-wrap items-center pt-10">
          <div className="w-1/2 max-md:w-full max-md:pb-5">
            <img
              src={ApiUrl + data.Transformative_Image.data.attributes.url}
              alt={
                data.Transformative_Image.data.attributes.alternativeText ?? ""
              }
              className={`w-11/12 box ${
                isVisible ? "animate__animated animate__fadeInLeft" : ""
              }`}
            />
          </div>
          <div
            className={`w-1/2 bg-[#ffffff1a] p-10 rounded-lg max-md:w-full text-lg max-md:text-sm ${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <div className="flex flex-col gap-2">
              <div
                className="flex gap-3   circle
              "
              >
                <div id="icon " className="pt-[0.3rem]">
                  {bulletIcon}
                </div>
                <p>{data.Transformative_Point_One}</p>
              </div>
              <div
                className="flex gap-3 circle
              "
              >
                <div id="icon" className="pt-[0.3rem]">
                  {bulletIcon}
                </div>
                <p>{data.Transformative_Point_Two}</p>
              </div>
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Power;
