import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import assets from "../../assets/assets";
import AccordionItem from "./AccordionSetm";
import ParentContainer from "../../components/ParentContainer";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";
gsap.registerPlugin(ScrollTrigger);

const RedMed = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null); // Initialize to keep the first item open by default

  const handleToggle = (index) => {
    if (openIndex !== index) {
      setOpenIndex(index);
    } else setOpenIndex(null);
  };

  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  const [beforeDelimiter1, afterDelimiter1] = data.Reg_Med_Heading.split("||");
  return (
    <ParentContainer className="px-36 py-20 !overflow-hidden ">
      <div className="flex flex-wrap items-center overflow-hidden" ref={main}>
        <div
          className={`w-2/5 max-md:w-full max-md:mb-5 max-lg:hidden box${
            isVisible ? "animate__animated animate__slideInLeft " : ""
          }`}
        >
          <img
            src={ApiUrl + data.Reg_Med_Image.data.attributes.url}
            alt={data.Reg_Med_Image.data.attributes.alternativeText}
            className="w-4/5 max-md:w-full"
          />
        </div>
        <div
          className={`w-3/5 flex flex-col gap-5 max-lg:w-full  ${
            isVisible ? "animate__animated animate__slideInRight " : ""
          }`}
        >
          <h2 className="text-5xl font-bold text-left w-full mb-2 circle max-md:text-2xl">
            {beforeDelimiter1}
            <span className="text-custom-primary">
              {afterDelimiter1} <br />
            </span>
          </h2>
          <p className="text-base circle max-md:text-sm ">
            {data.Reg_Med_Para}
          </p>
          <p className="text-base font-semibold circle max-md:text-sm">
            {/* {data.Reg_Med_Faq_Heading}{" "} */}
          </p>

          <div className="grid grid-cols-2 gap-2 circle max-md:grid-cols-1 !overflow-hidden">
            {data.Reg_Med_Faqs.map((faq, index) => (
              <AccordionItem
                title={faq.Question}
                isOpen={openIndex === index}
                onClick={() => handleToggle(index)}
              >
                <p>{faq.Answer}</p>
              </AccordionItem>
            ))}
          </div>
        </div>
      </div>
    </ParentContainer>
  );
};

export default RedMed;
