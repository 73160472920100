import React, { useEffect, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";

const RelatedBlog = ({ data }) => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            `/api/blog-categories/${data}?populate=blogs,blogs.Image,blogs.blog_category`
        );

        setSource(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  console.log("category", source);

  var blogItem = (
    <div>
      <div id="imageindex">
        <img
          src={assets.blogindexImage}
          alt={"Blog Image"}
          className="w-full object-cover object-top h-[250px] max-md:h-auto rounded-lg"
        />
      </div>
      <div className="py-5">
        <p
          className="text-xs text-gray-500
           "
        >
          PREGNANCY
        </p>
        <h2 className="text-xl font-semibold underline max-md:text-base">
          10 Pregnancy Essentials: A Vital Checklist for Expecting Parents
        </h2>
      </div>
    </div>
  );

  return (
    <ParentContainer>
      <h3 className="text-3xl font-bold text-center max-md:text-2xl">
        تصفح المدونات المشابهة الآن
      </h3>
      <div className="custom-parent grid grid-cols-4 gap-5 pt-8 max-md:!grid-cols-1 max-lg:grid-cols-2">
        {source.data.attributes.blogs.data.slice(0, 4).map((blog, index) => (
          <div key={index}>
            <div id="imageindex">
              {blog.attributes.Image.data && (
                <img
                  src={ApiUrl + blog.attributes.Image.data.attributes.url}
                  alt={
                    blog.attributes.Image.data.attributes.alternativeText ?? ""
                  }
                  className="w-full object-cover object-top h-[250px] max-md:h-auto rounded-lg"
                />
              )}
            </div>
            <div className="py-5">
              <p
                className="text-xs text-gray-500
               "
              >
                {blog.attributes.blog_category.data.attributes.Category}
              </p>
              <a
                href={`/blog-single/${blog.attributes.Title.replaceAll("-", "_")
                  .replaceAll(" ", "-")
                  .replaceAll("?", "&*")}`}
                className="text-xl font-semibold underline max-md:text-base"
              >
                {blog.attributes.Title}
              </a>
            </div>
          </div>
        ))}
      </div>
    </ParentContainer>
  );
};

export default RelatedBlog;
