import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import assets from "../../assets/assets";
import { Waypoint } from "react-waypoint";
import "animate.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ApiUrl } from "../../components/ApiUrl";

gsap.registerPlugin(ScrollTrigger);

const CellSaveKit = ({ data }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleEnter = () => {
    setIsVisible(true);
  };

  const main = useRef();
  const parentRef = useRef();
  const [beforeDelimiter, afterDelimiter] = data.Kit_Heading.split("||");

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={main}>
      <ParentContainer containerStyle={"bg-[#F7F6FF] overflow-hidden"}>
        <h2 className="text-4xl  font-bold pb-5 box max-md:text-2xl">
          {beforeDelimiter}
          <span className=" text-custom-primary ">{afterDelimiter}</span>
        </h2>
        <p className="text-base w-full box max-md:text-sm">{data.Kit_Para}</p>
        <div className="flex flex-wrap py-10 ">
          <div
            className={`w-1/2  max-md:w-full border-r flex flex-col gap-5 ${
              isVisible ? "animate__animated animate__fadeInLeft" : ""
            }`}
          >
            <div className="ml-5">
              {data.Points.map((point, index) => (
                <div
                  key={index}
                  class="flex box items-center relative group hover:bg-gradient-to-r from-custom-primary to-custom-secondary rounded-lg w-full mr-1"
                >
                  <div class="absolute -right-[4px] h-4/5 border-l-4 border-current hidden group-hover:block"></div>
                  <div class="p-7 group-hover:text-white font-semibold pl-10 w-full">
                    {point.List}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div
            className={`w-1/2 max-md:w-full max-md:-order-1${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            <div class="w-full h-full flex justify-center items-center">
              {data.Kit_Heading && (
                <img
                  src={ApiUrl + data.Kit_Image.data.attributes.url}
                  alt={data.Kit_Image.data.attributes.alternativeText ?? ""}
                  className="w-4/6 circle"
                />
              )}
            </div>
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default CellSaveKit;
