import React from "react";
import ParentContainer from "../components/ParentContainer";

const TermsCondition = () => {
  return (
    <ParentContainer>
      <div dir="rtl" className="flex flex-col gap-3">
        <h1 className="text-5xl font-bold  max-md:text-2xl max-lg:w-full max-lg:mb-4">
          الشروط والأحكام
        </h1>

        <p>&nbsp;</p>
        <p>
          تحدد هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام موقع
          <strong>CellSave Arabia FZ-LLC</strong>الموجود على الرابط:
          https://cellsave.com
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p className="font-normal">
          تحدد هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام موقع{" "}
          <strong>CellSave Arabia FZ-LLC</strong>
          <span className="font-normal">، الموجود على الرابط:</span>
          <a href="https://cellsave.com/" className="text-blue-500">
            <span className="font-normal">https://cellsave.com/</span>
          </a>
        </p>
        <p>
          <strong>
            تقوم CellSave Arabia FZ-LLC بإدارة هذا الموقع ("الموقع"). تحدد هذه
            الشروط والأحكام ("الشروط") لاستخدام موقع CellSave Arabia من خلال
            الوصول إلى هذا الموقع، فإنك توافق على الالتزام بهذه الشروط وتقر
            بمسؤوليتك عن الامتثال لأي قوانين سارية. إذا كنت لا توافق على جميع
            الشروط والأحكام الواردة في هذه الصفحة، يُحظر عليك استخدام الموقع. لا
            يجوز للمستخدمين القُصَّر/الذين تقل أعمارهم عن 18 عامًا التسجيل
            كمستخدمين للموقع أو إجراء أي معاملات أو استخدام الموقع.
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p className="font-normal">
          تنطبق المصطلحات التالية على شروط الاستخدام، وسياسة الخصوصية، وجميع
          الاتفاقيات:
        </p>
        <ul className="list-inside list-disc">
          <li className="font-normal">
            "العميل"، "أنت" و"الخاص بك" تشير إليك، الشخص الذي يصل إلى هذا الموقع
            ويلتزم بشروط وأحكام الشركة.
          </li>
          <li className="font-normal">
            "الشركة"، "نحن"، "لنا"، و"خاصتنا" تشير إلى شركتنا.
          </li>
          <li className="font-normal">
            "الطرف"، "الأطراف"، أو "نحن" تشير إلى كل من العميل والشركة.
          </li>
        </ul>
        <p className="font-normal">
          يتم استخدام أي من المصطلحات المذكورة أعلاه أو غيرها من الكلمات بصيغة
          المفرد أو الجمع أو بحروف كبيرة أو بصيغة المذكر/المؤنث أو "هم" بشكل
          تبادلي، وبالتالي تعتبر جميعها متطابقة في المعنى.
        </p>
        <p className="font-normal">
          تشير جميع هذه المصطلحات إلى العرض والقبول ومقابل الدفع اللازم لتقديم
          المساعدة للعميل بالطريقة الأنسب لتحقيق احتياجاته فيما يتعلق بالخدمات
          التي تقدمها الشركة، وفقًا للقوانين السارية في{" "}
          <strong>دولة الإمارات العربية المتحدة</strong>، التي تعتبر بلد إقامتنا
          القانوني.
        </p>
        <p className="font-normal">
          يتم الفصل في جميع النزاعات الناشئة فيما يتعلق بذلك فقط من قبل محكمة
          ذات اختصاص قضائي في <strong>الإمارات العربية المتحدة</strong>. كما
          أننا لن نقوم بالتجارة مع أو تقديم أي خدمات للأفراد أو الكيانات المدرجة
          على قوائم العقوبات الصادرة عن{" "}
          <strong>مكتب مراقبة الأصول الأجنبية (OFAC)</strong> والدول المحظورة،
          وفقًا لقوانين دولة الإمارات العربية المتحدة.
        </p>
        <p className="font-normal">&nbsp;</p>
        <p className="font-normal">ملفات تعريف الارتباط (الكوكيز)</p>
        <p className="font-normal">
          نستخدم ملفات تعريف الارتباط. من خلال الوصول إلى هذا الموقع، فإنك توافق
          على استخدام ملفات تعريف الارتباط وفقًا{" "}
          <strong>لسياسة الخصوصية الخاصة بـ CellSave Arabia FZ-LLC</strong>.
        </p>
        <p className="font-normal">
          تستخدم معظم المواقع التفاعلية ملفات تعريف الارتباط لتمكيننا من استرجاع
          تفاصيل المستخدم في كل زيارة. تُستخدم ملفات تعريف الارتباط على موقعنا
          لتفعيل وظائف معينة تسهل تجربة الزوار أثناء تصفحهم للموقع. قد يستخدم
          بعض شركائنا في الإعلانات أو البرامج التابعة لنا أيضًا ملفات تعريف
          الارتباط.
        </p>
        <p className="font-normal">&nbsp;</p>
        <p className="font-normal">الترخيص</p>
        <p className="font-normal">
          ما لم يُنص على خلاف ذلك، فإن <strong>CellSave Arabia FZ-LLC</strong>{" "}
          و/أو الجهات المرخِّصة لها تمتلك حقوق الملكية الفكرية لجميع المواد
          الموجودة على{" "}
          <strong>CellSave Arabia FZ-LLC - بنك العائلة للخلايا الجذعية</strong>.
          جميع حقوق الملكية الفكرية محفوظة. يمكنك الوصول إلى محتوى موقع{" "}
          <strong>CellSave Arabia</strong> لاستخدامك الشخصي فقط، وذلك وفقًا
          للقيود المنصوص عليها في هذه الشروط والأحكام.
        </p>
        <p className="font-normal">يُحظر عليك:</p>
        <ul className="list-inside list-disc">
          <li className="font-normal">
            إعادة نشر المواد من موقع <strong>CellSave Arabia</strong>
          </li>
          <li className="font-normal">
            بيع أو تأجير أو ترخيص المواد من موقع{" "}
            <strong>CellSave Arabia</strong>
          </li>
          <li className="font-normal">
            استنساخ أو تكرار أو نسخ المواد من موقع{" "}
            <strong>CellSave Arabia</strong>
          </li>
          <li className="font-normal">
            إعادة توزيع محتوى موقع <strong>CellSave Arabia</strong>
          </li>
        </ul>
        <p className="font-normal">&nbsp;</p>
        <p className="font-normal">التعليقات</p>
        <p className="font-normal">
          يتيح بعض أجزاء هذا الموقع للمستخدمين فرصة نشر وتبادل الآراء والمعلومات
          في مناطق معينة منه. لا تقوم <strong>CellSave Arabia</strong> بتصفية أو
          تعديل أو نشر أو مراجعة التعليقات قبل ظهورها على الموقع. لا تعكس
          التعليقات آراء أو وجهات نظر <strong>CellSave Arabia</strong> أو
          وكلائها أو الشركات التابعة لها، وإنما تعكس فقط آراء ووجهات نظر الشخص
          الذي قام بنشرها.
        </p>
        <p className="font-normal">
          إلى الحد الذي يسمح به القانون المعمول به، لن تكون{" "}
          <strong>CellSave Arabia</strong> مسؤولة عن التعليقات أو أي مسؤولية أو
          أضرار أو نفقات ناتجة عن أي استخدام أو نشر أو ظهور للتعليقات على هذا
          الموقع. تحتفظ <strong>CellSave Arabia</strong> بالحق في مراقبة جميع
          التعليقات وإزالة أي تعليق يُعتبر غير مناسب أو مسيئًا أو مخالفًا لهذه
          الشروط والأحكام.
        </p>
        <p className="font-normal">أنت تضمن وتقر بأن:</p>
        <ul className="list-inside list-disc">
          <li className="font-normal">
            لديك الحق في نشر التعليقات على موقعنا وتمتلك جميع التراخيص
            والموافقات اللازمة للقيام بذلك.
          </li>
          <li className="font-normal">
            التعليقات لا تنتهك أي حقوق ملكية فكرية، بما في ذلك على سبيل المثال
            لا الحصر حقوق النشر أو براءات الاختراع أو العلامات التجارية لأي طرف
            ثالث.
          </li>
          <li className="font-normal">
            التعليقات لا تحتوي على أي مواد تشهيرية أو مسيئة أو غير لائقة أو غير
            قانونية أو تشكل انتهاكًا للخصوصية.
          </li>
          <li className="font-normal">
            لن يتم استخدام التعليقات للترويج للأعمال التجارية أو الإعلان أو
            تقديم أنشطة تجارية أو أنشطة غير قانونية.
          </li>
        </ul>
        <p className="font-normal">
          أنت تمنح بموجب هذا <strong>CellSave Arabia FZ-LLC</strong> ترخيصًا غير
          حصري لاستخدام وتعـديل وإعادة إنتاج والتصريح للآخرين باستخدام وتعـديل
          وإعادة إنتاج أي من تعليقاتك بجميع الأشكال والصيغ والوسائط.
        </p>
        <p className="font-normal">&nbsp;</p>
        <p className="font-normal">الربط التشعبي بمحتوانا</p>
        <p className="font-normal">
          يمكن للمنظمات التالية الارتباط بموقعنا الإلكتروني دون الحاجة إلى
          موافقة كتابية مسبقة:
        </p>
        <ul className="list-inside list-disc">
          <li className="font-normal">الوكالات الحكومية؛</li>
          <li className="font-normal">محركات البحث؛</li>
          <li className="font-normal">المؤسسات الإخبارية؛</li>
          <li className="font-normal">
            موزعو الأدلة الإلكترونية، بشرط أن يرتبطوا بموقعنا بنفس الطريقة التي
            يرتبطون بها بمواقع الشركات الأخرى المدرجة؛
          </li>
          <li className="font-normal">
            الشركات المعتمدة على نطاق النظام، باستثناء المنظمات غير الربحية التي
            تقوم بالتسويق، ومراكز التسوق الخيرية، ومجموعات جمع التبرعات الخيرية،
            والتي لا يُسمح لها بالارتباط بموقعنا.
          </li>
        </ul>
        <p className="font-normal">
          يمكن لهذه المنظمات الارتباط بصفحتنا الرئيسية أو المنشورات أو أي
          معلومات أخرى على موقعنا الإلكتروني بشرط أن يكون الرابط:
        </p>
        <p className="font-normal">(أ) غير مضلل بأي شكل من الأشكال؛</p>
        <p className="font-normal">
          (ب) لا يوحي كذبًا برعاية أو تأييد أو موافقة{" "}
          <strong>CellSave Arabia</strong> للطرف المرتبط أو لمنتجاته وخدماته؛
        </p>
        <p className="font-normal">(ج) متناسبًا مع سياق موقع الطرف المرتبط.</p>

        <p className="font-normal">&nbsp;</p>
      </div>
    </ParentContainer>
  );
};

export default TermsCondition;
