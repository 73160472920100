import React, { useLayoutEffect, useRef, useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";

gsap.registerPlugin(ScrollTrigger);

const CellSaveNumbers = ({ data }) => {
  const mainRef = useRef(null);
  const parentRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 200, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 100%",
          end: "bottom 100%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, mainRef);

    return () => ctx.revert();
  }, []);

  useEffect(() => {
    const numbers = document.querySelectorAll(".number");
    numbers.forEach((number) => {
      const targetValue = parseInt(number.dataset.target, 10);
      gsap.to(number, {
        innerText: targetValue,
        duration: 2,
        scrollTrigger: {
          trigger: number,
          start: "top 90%",
          onEnter: () => {
            gsap.to(number, {
              innerText: targetValue,
              duration: 2,
              snap: { innerText: 1 },
              onUpdate: function () {
                number.innerText = Math.ceil(number.innerText) + " +";
              },
            });
          },
          onLeave: () => {
            gsap.to(number, {
              innerText: targetValue,
              duration: 0,
              snap: { innerText: 1 },
              onUpdate: function () {
                number.innerText = targetValue + " +";
              },
            });
          },
        },
      });
    });
  }, [data]);

  const numbers = [
    {
      numbers: data.CellSave_Number_Number_One,
      text: data.CellSave_Number_Text_One,
    },
    {
      numbers: data.CellSave_Number_Number_Two,
      text: data.CellSave_Number_Text_Two,
    },
    {
      numbers: data.CellSave_Number_Number_Three,
      text: data.CellSave_Number_Text_Three,
    },
    {
      numbers: data.CellSave_Number_Number_Four,
      text: data.CellSave_Number_Text_Four,
    },
    {
      numbers: data.CellSave_Number_Number_Five,
      text: data.CellSave_Number_Text_Five,
    },
  ];

  return (
    <div ref={mainRef}>
      <ParentContainer
        containerStyle="flex flex-wrap items-start bg-gradient-to-r from-custom-primary to-custom-secondary justify-center max-md:py-12"
        containerHeight="py-36"
      >
        <h2 className="box text-5xl font-bold text-left text-white w-full max-md:text-2xl">
          {data.CellSave_Number_Heading}
        </h2>
        <div
          ref={parentRef}
          className="w-full grid grid-cols-5 gap-5 pt-20 max-lg:pt-5 max-lg:grid-cols-2"
        >
          {numbers.map((number, index) => (
            <div
              key={index}
              className="cellsavenumbersabout imgs w-full p-4 max-lg:p-10 max-lg:px-4 flex justify-center rounded-xl border-white border flex-wrap items-center"
            >
              <h3
                className="number w-full leading-10 text-center text-3xl font-bold text-white max-md:text-2xl"
                data-target={number.numbers}
              >
                0 +
              </h3>
              <p className="w-4/5 text-center text-base text-white font-thin max-md:text-base">
                {number.text}
              </p>
            </div>
          ))}
        </div>
      </ParentContainer>
    </div>
  );
};

export default CellSaveNumbers;
