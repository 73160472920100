import React, { useState, useEffect, useRef } from "react";
import assets from "../assets/assets";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import { Link } from "react-router-dom";

const LoginPopup = ({ close }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginContainerRef = useRef(null);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(ApiUrl + "/api/auth/local", {
        identifier: username,
        password: password,
      });

      // Store the JWT token in local storage
      localStorage.setItem("jwt", response.data.jwt);
      localStorage.setItem("user", response.data.user.id);
      console.log(response);

      alert("Login successful!");
      window.location.href = "/profile/order-history";
      // Redirect or perform any other action after successful login
    } catch (error) {
      console.error("An error occurred during login", error.response);
      alert("Login failed!");
    }
  };

  const handleClickOutside = (event) => {
    if (
      loginContainerRef.current &&
      !loginContainerRef.current.contains(event.target)
    ) {
      close(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flex justify-center h-[100vh] items-center fixed w-full bg-[#000000ba] max-md:h-[100vh] z-[9999]">
      <div
        ref={loginContainerRef}
        className="bg-white w-1/3 py-24 shadow-lg rounded-2xl relative px-16 flex flex-col gap-5 max-md:w-full max-md:px-4 max-md:py-20"
        style={{
          backgroundImage: `url(${assets.loginpopup})`,
          backgroundSize: "35%",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "top",
          transform: "scaleX(-1)",
        }}
      >
        <div style={{ transform: "scaleX(-1)" }}>
          <h3 className="text-5xl  font-bold text-custom-primary leading-none">
            تسجيل الدخول
          </h3>
          <p className="text-xl font-semibold">
            الوصول إلى نموذج تنشيط حسابك، والفواتير والمدفوعات، والتنزيلات.{" "}
          </p>
          <form onSubmit={handleLogin} className="grid grid-cols-4 gap-2">
            <div className="col-span-4 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="username">
                اسم المستخدم
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg"
                type="text"
                name="username"
                placeholder="اسم المستخدم"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="col-span-4 flex flex-col gap-2">
              <label className="font-semibold" htmlFor="password">
                كلمة المرور
              </label>
              <input
                className="bg-[#F6F6F6] p-3 rounded-lg"
                type="password"
                name="password"
                placeholder="كلمة المرور"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button className="bg-gradient-to-r from-custom-primary to-custom-secondary text-white px-5 py-3 mt-5 rounded-3xl font-semibold col-span-4">
              تسجيل الدخول
            </button>
          </form>

          <div
            className="absolute right-0 top-[-60px] cursor-pointer"
            onClick={() => close(false)}
          >
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24 44C35 44 44 35 44 24C44 13 35 4 24 4C13 4 4 13 4 24C4 35 13 44 24 44Z"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18.3398 29.6598L29.6598 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M29.6598 29.6598L18.3398 18.3398"
                stroke="#37424A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
