import React, { useLayoutEffect, useRef, useState } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import assets from "../../assets/assets";
import AccordionItem from "./AccordionItem";
import ParentContainer from "../../components/ParentContainer";
import Button from "../../components/Button";
import { ApiUrl } from "../../components/ApiUrl";

gsap.registerPlugin(ScrollTrigger);

const WhySelect = ({ data }) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [beforeDelimiter, afterDelimiter] = data.Why_Select_Heading.split("||");

  const mainRef = useRef(null);
  const parentRef = useRef(null);

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: 1500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: box,
              start: "bottom 70%",
              end: "top 70%",
              scrub: 2,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 85%",
              end: "top 50%",
              scrub: 4,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      images.forEach((img) => {
        gsap.fromTo(
          img,
          { scale: 0.5, opacity: 0, x: -300 },
          {
            scale: 1,
            opacity: 1,
            x: 0,
            scrollTrigger: {
              trigger: img,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 4,
            },
          }
        );
      });

      const imgss = gsap.utils.toArray(".imgss");
      gsap.from(imgss, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 80%",
          end: "bottom 80%",
          scrub: 1,
          toggleActions: "play none none none",
          // markers: true,
        },
      });
    }, mainRef);

    return () => ctx.revert();
  }, []);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex !== index ? index : null));
  };

  return (
    <div ref={mainRef}>
      <ParentContainer containerStyle={"bg-white overflow-hidden"}>
        <div className="flex justify-center flex-wrap py-10 items-center">
          <h2 className="text-5xl font-bold text-left w-1/2 imgs max-md:text-2xl max-md:w-full max-md:pb-4">
            {beforeDelimiter}
            <span className="text-custom-primary">
              {afterDelimiter} <br />
            </span>
          </h2>
          <Button
            Linkto={"/checkout"}
            titleof={"Order Now"}
            CustomStyle={"!justify-end box max-md:!justify-start max-md:px-0"}
          />
        </div>
        <div className="flex flex-wrap items-center">
          <div className="w-2/5 imgs max-md:w-full max-md:pb-4 max-lg:hidden">
            <img
              src={ApiUrl + data.Why_Select_Image.data.attributes.url}
              alt={data.Why_Select_Image.data.attributes.alternativeText ?? ""}
              className="w-4/5 max-md:w-full"
            />
          </div>
          <div
            ref={parentRef}
            className={`w-3/5 flex flex-col gap-5 max-lg:w-full ${
              isVisible ? "animate__animated animate__fadeInRight" : ""
            }`}
          >
            {data.Faqs.map((faq, index) => (
              <AccordionItem
                title={faq.Question}
                isOpen={openIndex === index}
                onClick={() => handleToggle(index)}
                customStyle={"imgss"}
              >
                <p>{faq.Answer}</p>
              </AccordionItem>
            ))}
          </div>
        </div>
      </ParentContainer>
    </div>
  );
};

export default WhySelect;
