import React, { useEffect, useState } from "react";
import BlogMainSection from "./BlogSingle/BlogMainSection";
import Preservation from "./BlogSingle/Preservation";
import RelatedBlog from "./BlogSingle/RelatedBlog";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { useParams, useLocation } from "react-router-dom";

const BlogSingle = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    console.log("id", id);
    const title = id
      .replaceAll("-", " ")
      .replaceAll("^", "-")
      .replaceAll("&*", "?");
    console.log("title", title);
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl + `/api/blogs?filters[Title][$eq]=${title}`
        );
        console.log("response", response.data, "title", title);
        try {
          const response2 = await axios.get(
            ApiUrl +
              `/api/blogs/${response.data.data[0].id}?populate=Image,blog_category`
          );
          setSource(response2.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, [id]);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }

  console.log("blog single", source);

  return (
    <div>
      <BlogMainSection data={source.data} />
      <RelatedBlog data={source.data.attributes.blog_category.data.id} />
    </div>
  );
};

export default BlogSingle;
