import React, { useEffect, useState } from "react";
import MainHeroSection from "../components/MainHeroSection";
import assets from "../assets/assets";
import MainAdvantagesSection from "../components/MainAdvantagesSection";
import MainValuable from "../components/MainValuable";
import TranslplantNumbers from "../components/TranslplantNumbers";
import LifeTimeBanner from "../components/LifeTimeBanner";
import axios from "axios";
import { ApiUrl } from "../components/ApiUrl";
import { Helmet } from "react-helmet";

const CordBlood = () => {
  const lineBreak = false;
  const AdvantagePara = false;

  const [cord, setCord] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/cord-blood?populate=Translpant_Numbers,Translpant_Numbers.image,Hero_Section,Hero_Section.Video,Advantages,Advantages.Image,Advantages.Advantages,Valuable,Valuable.Points,seo,seo.keywords"
        );

        setCord(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!cord) {
    return <div>Error loading data.</div>;
  }
  const [beforeDelimiter, afterDelimiter] =
    cord.data.attributes.Hero_Section.Heading.split("||");
  const [beforeDelimiter1, afterDelimiter1] =
    cord.data.attributes.Advantages.Heading.split("||");
  const [beforeDelimiter2, afterDelimiter2] =
    cord.data.attributes.Valuable.Heading.split("||");

  return (
    <div>
      {/* <Helmet>
        <title>{cord?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={cord?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            cord?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet> */}
      <MainHeroSection
        HeadingBlack={beforeDelimiter}
        HeadingBreak={lineBreak}
        HeadingBlue={afterDelimiter}
        HeadingPara={cord.data.attributes.Hero_Section.Paragraph}
        IconOne={cord.data.attributes.Hero_Section.Point_One}
        IconTwo={cord.data.attributes.Hero_Section.Point_Two}
        IconThree={cord.data.attributes.Hero_Section.Point_Three}
        BannerImage={
          ApiUrl + cord.data.attributes.Hero_Section.Video.data.attributes.url
        }
        BannerAlt={
          cord.data.attributes.Hero_Section.Video.data.attributes
            .alternativeText ?? ""
        }
        BannerText={cord.data.attributes.Hero_Section.Video_Heading}
      />
      <MainAdvantagesSection
        HeadingBlack={beforeDelimiter1}
        HeadingBlue={afterDelimiter1}
        isPara={true}
        Image={
          ApiUrl + cord.data.attributes.Advantages.Image.data.attributes.url
        }
        ImageAlt={
          cord.data.attributes.Advantages.Image.data.attributes
            .alternativeText ?? ""
        }
        Para={cord.data.attributes.Advantages.Para}
        TwoImages={true}
        Advantages={cord.data.attributes.Advantages.Advantages.map(
          (advantage, index) => advantage.List
        )}
      />
      <MainValuable
        HeadingBlack={beforeDelimiter2}
        HeadingBlue={afterDelimiter2}
        HeadingPara={cord.data.attributes.Valuable.Para}
        isButton={false}
        ValueList={cord.data.attributes.Valuable.Points.map(
          (point, index) => point.List
        )}
      />
      <TranslplantNumbers
        backgroundImage={
          ApiUrl +
          cord.data.attributes.Translpant_Numbers.image.data.attributes.url
        }
        mobileBackgroundImage={assets.mt4}
        numberone={cord.data.attributes.Translpant_Numbers.Number_One}
        numbertwo={cord.data.attributes.Translpant_Numbers.Number_Two}
        numberthree={cord.data.attributes.Translpant_Numbers.Number_Three}
        textone={cord.data.attributes.Translpant_Numbers.Text_One}
        texttwo={cord.data.attributes.Translpant_Numbers.Text_Two}
        textthree={cord.data.attributes.Translpant_Numbers.Text_Three}
        title={cord.data.attributes.Translpant_Numbers.Heading}
      />
      <LifeTimeBanner Heading={cord.data.attributes.Banner_Text} />
    </div>
  );
};

export default CordBlood;
