import React, { useEffect, useState } from "react";
import ParentContainer from "../components/ParentContainer";
import assets from "../assets/assets";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { useParams } from "react-router-dom";
import RichTextRenderer from "../components/RichTextRenderer";

const FeaturedMomsSingle = () => {
  const [source, setSource] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  useEffect(() => {
    const fetchHomePageData = async () => {
      const title = id
        .replaceAll("-", " ")
        .replaceAll("^", "-")
        .replaceAll("&*", "?");
      console.log("title", title);
      try {
        const response = await axios.get(
          ApiUrl + `/api/blogs?locale=ar&filters[Title][$eq]=${title}`
        );

        console.log("response", response.data, "title", title);
        try {
          const response2 = await axios.get(
            ApiUrl +
              `/api/blogs/${response.data.data[0].id}?locale=ar&populate=Image,blog_category`
          );
          setSource(response2.data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!source) {
    return <div>Error loading data.</div>;
  }
  // const [
  //   beforeDelimiter,
  //   afterDelimiter,
  // ] = source.data.attributes.Hero_Section.Heading.split("||");

  console.log("Moms", source);
  return (
    <div dir="rtl">
      <ParentContainer containerStyle={"flex max-md:flex-wrap"}>
        <div className="w-1/3 max-md:w-full">
          <img
            src={ApiUrl + source.data.attributes.Image.data.attributes.url}
            alt={
              source.data.attributes.Image.data.attributes.alternativeText ?? ""
            }
          />
        </div>
        <div className="w-2/3 pr-5 flex flex-col gap-4 max-md:w-full max-md:pl-0 max-md:pt-4">
          <h3 className="text-3xl font-bold text-custom-primary">
            {source?.data?.attributes?.Title ?? ""}
          </h3>
          {source.data.attributes.body_alt?.length > 1 ? (
            <div className="text-black [&_a]:text-blue-500">
              <RichTextRenderer content={source.data.attributes.body_alt} />
            </div>
          ) : (
            <div
              className="[&_ul]:list-disc [&_ul]:pl-8  [&_ol]:pl-8"
              id="blogHTML"
              dangerouslySetInnerHTML={{ __html: source.data.attributes.Body }}
            />
          )}
        </div>
      </ParentContainer>
    </div>
  );
};

export default FeaturedMomsSingle;
