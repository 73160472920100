import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";

const MobileNav = ({ onMessageUpdate, open }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);
  const [isDropdownOpen3, setIsDropdownOpen3] = useState(false);
  const [lang, setLangNow] = useState();
  const [timeoutId, setTimeoutId] = useState(null);
  const location = useLocation();
  // Use useState to store the language

  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname.startsWith("/ar")) {
      setLangNow("ar");
    } else {
      setLangNow("en");
    }
  }, []);
  const switchLanguage = () => {
    if (location.pathname.startsWith("/ar")) {
      if (location.pathname.includes("/ar/blog-single")) {
        navigate("/blog"); // navigate to the English path
      } else if (location.pathname.includes("/ar/featured-moms-single")) {
        navigate("/featured-moms"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/6")) {
        navigate("/product/2"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/5")) {
        navigate("/product/3"); // navigate to the English path
      } else if (location.pathname.includes("/ar/product/4")) {
        navigate("/product/1"); // navigate to the English path
      } else {
        const newPath = location.pathname.replace("/ar", "") || "/";

        navigate(newPath); // navigate to the English path
      }
      setLangNow("en");
      window.dispatchEvent(new Event("languageChange"));

      // Force full page reload:
      window.location.reload();
    } else {
      if (location.pathname.includes("/blog-single")) {
        navigate("/ar/blog"); // navigate to the English path
      } else if (location.pathname.includes("/featured-moms-single")) {
        navigate("/ar/featured-moms"); // navigate to the English path
      } else if (location.pathname.includes("/product/2")) {
        navigate("/ar/product/6"); // navigate to the English path
      } else if (location.pathname.includes("/product/3")) {
        navigate("/ar/product/5"); // navigate to the English path
      } else if (location.pathname.includes("/product/1")) {
        navigate("/ar/product/4"); // navigate to the English path
      } else {
        const newPath = "/ar" + location.pathname;
        navigate(newPath); // navigate to the Arabic path
      }

      setLangNow("ar");
      window.dispatchEvent(new Event("languageChange"));

      // Force full page reload:
      window.location.reload();
    }
  };

  const handleClickMobile = () => {
    onMessageUpdate(false);
  };

  useEffect(() => {
    // Reset state when the location changes
    setIsDropdownOpen(false);
    setIsDropdownOpen2(false);
    setIsDropdownOpen3(false);
  }, [location]);
  return (
    <div
      className={` min-h-screen overflow-y-auto w-full bg-white flex items-center justify-start fixed top-0 ${
        lang === "ar" ? "pr-6" : "pl-6"
      } `}
    >
      <div className="flex  ">
        <ul className="flex flex-col w-full  justify-between content-center gap-3  text-xl">
          <li>
            <a
              href={lang == "ar" ? "/ar/about" : "/about"}
              className="font-semibold"
            >
              {lang == "ar" ? "حول سيل سيف" : "About CellSave"}
            </a>
          </li>
          <li className="relative flex max-lg:flex-wrap content-center justify-start  gap-2 	">
            <div className="flex  gap-2 justify-center">
              <button
                onClick={() => setIsDropdownOpen((prev) => !prev)}
                className={`flex items-center justify-start gap-2 w-full font-semibold ${
                  isDropdownOpen ? "underline" : ""
                } `}
              >
                {lang == "ar" ? "استكشف" : "Discover"}

                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {isDropdownOpen ? (
                    <path
                      d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  ) : (
                    <path
                      d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )}
                </svg>
              </button>
            </div>
            {isDropdownOpen && (
              <div className=" top-full -left-1/2  py-2 rounded-lg z-10 w-full p-4">
                <a
                  href={
                    lang == "ar"
                      ? "/ar/stem-cells-sceince"
                      : "/stem-cells-sceince"
                  }
                  className={`   block px-4 py-2  hover:bg-gray-200`}
                >
                  {lang == "ar" ? "علم الخلايا الجذعية" : "Stem Cells Science"}{" "}
                </a>
                <a
                  href={
                    lang == "ar"
                      ? "/ar/regenerative-medicine"
                      : "/regenerative-medicine"
                  }
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  {lang == "ar" ? "الطب التجديدي" : "Regenerative Medicine"}{" "}
                </a>
                <div className="flex items-center justify-start  gap-2 w-full">
                  <button className="  flex items-center justify-start relative   hover:bg-gray-200 w-auto">
                    <a
                      href={
                        lang == "ar"
                          ? "/ar/sources-of-stem-cell"
                          : "/sources-of-stem-cell"
                      }
                      className={`  ${
                        isDropdownOpen3 ? "underline" : ""
                      } block px-4 py-2  hover:bg-gray-200`}
                    >
                      {lang == "ar"
                        ? "مصادر الخلايا الجذعية"
                        : "Sources of Stem Cells"}{" "}
                    </a>
                  </button>
                  <svg
                    className="cursor-pointer   w-8"
                    width="10"
                    height="6"
                    viewBox="0 0 10 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setIsDropdownOpen3((prev) => !prev)}
                  >
                    {isDropdownOpen3 ? (
                      <path
                        d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    ) : (
                      <path
                        d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    )}
                  </svg>
                </div>
                {isDropdownOpen3 && (
                  <div className=" bg-white py-2 w-full rounded-lg z-10 px-4">
                    <a
                      href={lang == "ar" ? "/ar/cord-blood" : "/cord-blood"}
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      {lang == "ar" ? "دم الحبل السري" : "Cord Blood"}
                    </a>
                    <a
                      href={lang == "ar" ? "/ar/cord-tissue" : "/cord-tissue"}
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      {lang == "ar" ? "نسيج الحبل السري" : "Cord Tissue"}
                    </a>
                    <a
                      href={
                        lang == "ar"
                          ? "/ar/placenta-tissue"
                          : "/placenta-tissue"
                      }
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      {lang == "ar" ? "نسيج المشيمة" : "Placenta Tissue"}
                    </a>
                    <a
                      href={lang == "ar" ? "/ar/cord-vessel" : "/cord-vessel"}
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      {lang == "ar" ? "أوعية الحبل السري" : "Cord Vessel"}
                    </a>
                    <a
                      href={
                        lang == "ar" ? "/ar/amnion-tissue" : "/amnion-tissue"
                      }
                      className="block px-4 py-2  hover:bg-gray-200"
                    >
                      {lang == "ar" ? "النسج الأمنيوسي" : "Amnion Tissue"}
                    </a>
                  </div>
                )}
                <a
                  href={lang == "ar" ? "/ar/faqs" : "/faqs"}
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  {lang == "ar" ? "الأسئلة الشائعة" : "FAQs"}
                </a>
                <a
                  href={lang == "ar" ? "/ar/blog" : "/blog"}
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  {lang == "ar" ? "الموارد" : "Resources"}
                </a>
              </div>
            )}
          </li>
          <li className="relative flex flex-wrap justify-start content-center  gap-2">
            <button
              onClick={() => setIsDropdownOpen2((prev) => !prev)}
              className={`flex items-center gap-2 font-semibold	${
                isDropdownOpen2 ? "underline" : ""
              }`}
            >
              {lang == "ar" ? "الخدمات والأسعار" : "Services & Pricing"}{" "}
              <svg
                width="10"
                height="6"
                viewBox="0 0 10 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                {isDropdownOpen2 ? (
                  <path
                    d="M8.50586 4.75977L4.97586 1.23977L1.44586 4.75977"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                ) : (
                  <path
                    d="M1.43945 1.24023L4.96945 4.76023L8.49945 1.24023"
                    stroke="black"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                )}
              </svg>
            </button>

            {isDropdownOpen2 && (
              <div
                className={`bg-white py-2 w-full justify-start ${
                  lang === "ar" ? "text-right" : ""
                } rounded-lg z-10`}
              >
                <a
                  href={
                    lang == "ar" ? "/ar/service-pricing" : "/service-pricing"
                  }
                  className="block px-4 py-2  hover:bg-gray-200"
                >
                  {lang == "ar" ? "الخدمات والأسعار" : "Services & Pricing"}{" "}
                </a>
                <a
                  href={lang == "ar" ? "/ar/gift-registry" : "/gift-registry"}
                  className="block px-4 py-2 hover:bg-gray-200"
                >
                  {lang == "ar" ? "سجل الهدايا" : "Gift Registry"}
                </a>
              </div>
            )}
          </li>

          <li className="relative flex flex-wrap justify-start content-center   font-semibold">
            <a
              href={lang == "ar" ? "/ar/contact" : "/contact"}
              className="block  "
            >
              {lang == "ar" ? "اتصل بنا" : "Contact Us"}
            </a>
          </li>
          <li className="relative flex flex-wrap justify-start content-center gap-2">
            {localStorage.getItem("jwt") ? (
              // Render something if JWT is present
              <a
                href={
                  lang == "ar"
                    ? "/ar/profile/order-history"
                    : "/profile/order-history"
                }
              >
                {lang == "ar" ? "الحساب" : "Account"}
              </a> // Placeholder; replace with appropriate content for logged-in state
            ) : (
              <button
                onClick={() => open(true)}
                className={`flex items-center gap-2 font-semibold ${
                  isDropdownOpen2 ? "underline" : ""
                }`}
              >
                {lang == "ar" ? "تسجيل الدخول" : "Log In"}
              </button>
            )}
          </li>
          <li>
            <div
              className="font-semibold cursor-pointer "
              onClick={() => switchLanguage()}
            >
              {location.pathname.startsWith("/ar") ? "English" : "عربي"}
            </div>
          </li>
          <li className="relative flex flex-wrap justify-start content-center gap-2">
            <a
              href={lang == "ar" ? "/ar/checkout" : "/checkout"}
              className="bg-gradient-to-br from-custom-primary to-custom-secondary text-sm text-white px-5 py-2 rounded-3xl font-semibold"
            >
              {lang == "ar" ? "طلب الآن" : "Order Now"}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MobileNav;
