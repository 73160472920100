import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import LangProvider from "./components/LangContext";
import Gtag from "./components/Gtag";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Gtag />
    <App />
  </React.StrictMode>
);
