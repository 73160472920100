import React, { useState, useEffect } from "react";
import axios from "axios";

import MainLayout from "../components/MainLayout";
import HeroSection from "./HomePage/HeroSection";
import AboutSection from "./HomePage/AboutSection";
import Kit from "./HomePage/Kit";
import TextScroll from "./HomePage/TextScroll";
import Reasons from "./HomePage/Reasons";
import Introduction from "./HomePage/Introduction";
import About from "./HomePage/About";
import Safe from "./HomePage/Safe";
import ThreeImages from "./HomePage/ThreeImages";
import Packages from "./HomePage/Packages";
import Advantage from "./HomePage/Advantage";
import Featured from "./HomePage/Featured";
import Families from "./HomePage/Families";
import Partners from "./HomePage/Partners";
import Package from "../components/Packages";
import NewText from "./HomePage/NewText";
import { ApiUrl } from "../components/ApiUrl";
import SearchComponent from "./SearchComponent";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [homePageData, setHomePageData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHomePageData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            "/api/home-page?populate=Hero_Section_Background_Video,Hero_Section_Thumbnail_Image,Partners,Partners.Images,Partners.Images.image,Featured.Images,Featured.Images.image,Partners.Image_Two,Partners.Image_Three,Partners.Image_Four,Partners.Image_Five,CryoAdvanced,CryoUltimate,CryoInfinite,Regen_Med_Image,About_Section_Image_One,Safe_Section_Image_One,Safe_Section_Image_Two,Safe_Section_Image_Three,Featured.Image_One,Featured.Image_Two,Featured.Image_Three,Featured.Image_Four,Featured.Image_Five,Family,Family.Video_Image_Two,Family.Video_Image_One,Family.Video_Image_Three,seo,seo.keywords "
        );
        setHomePageData(response.data);
        console.log("homepage", response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, []);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!homePageData) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className="">
      {/* <Helmet>
        <title>{homePageData?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={homePageData?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            homePageData?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet> */}
      <HeroSection data={homePageData} />
      <AboutSection data={homePageData} />
      <Kit data={homePageData} />
      <Reasons data={homePageData} />
      <NewText data={homePageData} />
      <Introduction data={homePageData} />
      <About data={homePageData} />
      <Safe data={homePageData.data.attributes} />
      <ThreeImages data={homePageData.data.attributes} />
      <Packages data={homePageData.data.attributes} />
      <Package data={homePageData.data.attributes} />
      <Advantage data={homePageData.data.attributes} />
      <Featured data={homePageData.data.attributes.Featured} />
      <Families data={homePageData} />
      <Partners data={homePageData.data.attributes.Partners} />
    </div>
  );
};

export default HomePage;
