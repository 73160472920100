import { useEffect } from "react";

const GA_MEASUREMENT_ID = "AW-10817174292"; // Replace with your GA ID

const Gtag = () => {
  useEffect(() => {
    // Load gtag.js script dynamically
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
    document.head.appendChild(script);

    // Initialize gtag
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;

    gtag("js", new Date());
    gtag("config", GA_MEASUREMENT_ID);
  }, []);
};

export default Gtag;
