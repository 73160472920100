/* global gtag */
import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";
import ReCAPTCHA from "react-google-recaptcha";

const Form = ({ data }) => {
  const [phone, setPhone] = useState("");
  const [to, setTo] = useState("it@cellsave.com"); // Default recipient email
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [buttonText, setButtonText] = useState("Submit");
  const [captchaValue, setCaptchaValue] = useState(null);
  const siteKey = "6Lf3oNQqAAAAAGfhqZsxln2BSCvKeFkMHs1pHYON"; // Replace with your actual site key

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };
  // Function to trigger Google Ads conversion tracking
  const gtag_report_conversion = () => {
    if (typeof gtag !== "function") {
      console.warn("⚠️ gtag is not defined. Make sure the script is loaded.");
      return false;
    }

    console.log("✅ Triggering Google Ads conversion tracking...");
    if (typeof gtag === "function") {
      gtag("event", "conversion", {
        send_to: "AW-10817174292/2mDJCN6gpaIaEJT2g6Yo",
        value: 1.0,
        currency: "AED",
        event_callback: () => {
          console.log("📊 Google Ads Conversion tracking event sent!");
        },
      });
    }

    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    console.log("🚀 Form submission triggered");

    // **Check and trigger gtag conversion tracking**
    const conversionTriggered = gtag_report_conversion();
    console.log("📊 Google Ads Conversion triggered:", conversionTriggered);

    if (!captchaValue) {
      alert("Please verify the reCAPTCHA.");
      return;
    }
    // **Phone Number Validation**
    if (!phone) {
      alert("Phone number is required."); // Replace with a better UI element if desired
      return; // Prevent form submission
    }

    setButtonText("Sending..."); // Update button text

    const recipientEmails = to
      ? `${to},marketing@cellsave.com, rima.kobeissi@cellsave.com, catia.shamia@cellsave.com, Nadya.abbas@cellsave.com`
      : "marketing@cellsave.com, rima.kobeissi@cellsave.com, catia.shamia@cellsave.com, Nadya.abbas@cellsave.com";

    const formData = {
      to: recipientEmails,
      subject: subject || "New Contact Form Submission",
      text: text || "You have a new message from the Contact form.",
      html: `
        <p><strong>First Name:</strong> ${event.target["first-name"].value}</p>
        <p><strong>Last Name:</strong> ${event.target["last-name"].value}</p>
        <p><strong>Phone Number:</strong> ${event.target["phone-number"].value}</p>
        <p><strong>Email:</strong> ${event.target.email.value}</p>
        <p><strong>Message:</strong> ${event.target["message"].value}</p>
      `,
    };

    let emailSuccess = false;

    try {
      const response = await axios.post(
        ApiUrl + "/api/email/send", // Update this URL to your backend endpoint
        formData
      );

      console.log("Email sent successfully:", response.data);
      emailSuccess = true;
      setButtonText("Email Sent"); // Update button text on success
    } catch (emailError) {
      console.error(
        "Error sending email:",
        emailError.response ? emailError.response.data : emailError.message
      );
      setButtonText("Email Error! Try Again"); // Update button text in case of email error
    }

    if (emailSuccess) {
      try {
        // Submit data to the Salesforce endpoint
        const salesforceResponse = await axios.post(
          `${ApiUrl}/api/salesforce`,
          {
            FirstName: event.target["first-name"].value,
            LastName: event.target["last-name"].value,
            Email: event.target.email.value,
            MobilePhone: event.target["phone-number"].value,
            Origin_Program__c: "Contact",
            Description: event.target.message.value,
            Lead_Quality__c: "Warm",
            AttendingDoctor__c: "a00f400000XCppCAAT",
            Hospital_Clinics__c: "a01f400000KF5VMAA1",
            Program_Running__c: "Website",
            Origin_Hospital__c: "a01f400000KF5VMAA1",
            Origin_Doctor__c: "a00f400000XCppCAAT",
            Description: event.target["message"].value,
          }
        );

        console.log(
          "Data submitted to Salesforce successfully:",
          salesforceResponse.data
        );
      } catch (salesforceError) {
        console.error(
          "Error submitting data to Salesforce:",
          salesforceError.response
            ? salesforceError.response.data
            : salesforceError.message
        );
      }
    }
    setButtonText("Submission Successful"); // Update button text on Salesforce success

    // Reset button text after a delay
    setTimeout(() => {
      setButtonText("Submit");
    }, 3000);
  };

  return (
    <ParentContainer containerStyle={"flex flex-wrap max-md:!pb-0"}>
      <div className="w-1/3 max-lg:w-full max-lg:pb-4">
        <h3 className="text-4xl font-bold pb-2 max-md:text-2xl">
          {data.Contact_Form_Heading}
        </h3>
        <p className="text-base">{data.Contact_Form_Para}</p>
      </div>
      <form onSubmit={handleSubmit} className="w-2/3 max-lg:w-full">
        <div className="border-b pb-8 grid grid-cols-4 gap-4">
          <div className="col-span-2 w-full max-md:col-span-4">
            <label htmlFor="first-name" className="block mb-1">
              First Name
            </label>
            <input
              type="text"
              name="first-name"
              required
              id="first-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setSubject(`Contact from ${e.target.value}`)}
            />
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="last-name" className="block mb-1">
              Last Name
            </label>
            <input
              required
              type="text"
              name="last-name"
              id="last-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="phone-number" className="block mb-1">
              Phone Number
            </label>
            <PhoneInput
              country={"ae"}
              value={phone}
              required
              onChange={(phone) => setPhone(phone)}
              containerClass="w-full"
              inputProps={{
                name: "phone-number",
                required: true,
                autoFocus: true,
              }}
            />
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="email" className="block mb-1">
              Email Address
            </label>
            <input
              required
              type="email"
              name="email"
              id="email"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-4">
            <label htmlFor="topic" className="block mb-1">
              Topic
            </label>
            <select
              name="topic"
              required
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value="Newborn Stem Cells Information">
                Newborn Stem Cells Information
              </option>
              <option value="Storage Registration">Storage Registration</option>
              <option value="Other Inquiries">Other Inquiries</option>
              <option value="Marketing Inquiries">Marketing Inquiries</option>
              <option value="Healthcare Providers">Healthcare Providers</option>
              <option value="Billing Information">Billing Information</option>
            </select>
          </div>

          <div className="col-span-4">
            <label htmlFor="message" className="block mb-1">
              Message
            </label>
            <textarea
              name="message"
              required
              rows={4}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setText(e.target.value)}
            ></textarea>
          </div>
        </div>
        <ReCAPTCHA
          sitekey={siteKey}
          onChange={(value) => setCaptchaValue(value)}
        />
        <button
          type="submit"
          className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          {buttonText}
        </button>
      </form>
    </ParentContainer>
  );
};

export default Form;
