import React from "react";

const HeroSection = () => {
  return (
    <div className="">
      <h2 className="text-2xl font-bold p-8 bg-white border-b max-lg:px-0 max-md:text-xl">
        الملف الشخصي / التاريخ الصحي للأم
      </h2>
    </div>
  );
};

export default HeroSection;
