import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import assets from "../../assets/assets";
import ParentContainer from "../../components/ParentContainer";
import { Autoplay, Navigation } from "swiper/modules";
import { ApiUrl } from "../../components/ApiUrl";

const VideoOverlay = ({ isOpen, onClose, videoUrl }) => {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (e) => {
    // Check if the click is outside the video container
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div style={styles.overlay} onClick={handleOverlayClick}>
      <div style={styles.videoContainer} onClick={(e) => e.stopPropagation()}>
        <button style={styles.closeButton} onClick={onClose}>
          ×
        </button>
        <iframe
          src={`https://www.youtube.com/embed/${videoUrl}?autoplay=1&controls=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={styles.iframe}
        ></iframe>
      </div>
    </div>
  );
};

const playButton = (
  <svg
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.9123 64.1668C51.0206 64.1668 64.0789 51.1085 64.0789 35.0002C64.0789 18.8919 51.0206 5.8335 34.9123 5.8335C18.804 5.8335 5.74561 18.8919 5.74561 35.0002C5.74561 51.1085 18.804 64.1668 34.9123 64.1668Z"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.4917 35.6707V30.7998C25.4917 24.7332 29.7792 22.254 35.0292 25.2873L39.2584 27.7373L43.4875 30.1873C48.7375 33.2207 48.7375 38.179 43.4875 41.2123L39.2584 43.6623L35.0292 46.1123C29.7792 49.1457 25.4917 46.6665 25.4917 40.5998V35.6707Z"
      stroke="white"
      stroke-width="3"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  videoContainer: {
    position: "relative",
    width: "80%",
    maxWidth: "800px",
    aspectRatio: "16/9",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "transparent",
    border: "none",
    fontSize: "2rem",
    color: "#fff",
    cursor: "pointer",
  },
  iframe: {
    width: "100%",
    height: "100%",
  },
};

const Families = ({ data }) => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const openVideo = (url) => {
    setCurrentVideoUrl(url);
    setIsVideoOpen(true);
    document.body.style.overflow = "hidden"; // Disable scroll
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
    document.body.style.overflow = "auto"; // Enable scroll
  };

  const [ar, setAr] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("lang") === "ar") {
      setAr(true);
    } else {
      setAr(false);
    }
  }, []);

  return (
    <ParentContainer
      containerStyle={"flex justify-center flex-wrap gap-5 max-md:!py-10"}
      containerHeight={"py-40"}
    >
      <h2 className="text-5xl font-bold text-center w-1/2 mx-5 mb-10 max-md:text-2xl max-md:w-full">
        <span class="bg-gradient-to-r from-custom-primary to-custom-primary text-transparent bg-clip-text ">
          عائلات{" "}
        </span>
        سيل سيف
      </h2>

      <div className="w-full max-md:hidden flex justify-start items-center gap-2">
        <div className="swiper-button-prev">
          <svg
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className=" rotate-180"
          >
            <circle
              cx="25"
              cy="25"
              r="25"
              transform="matrix(0 -1 -1 0 50 50)"
              fill="#37424A"
            />
            <path
              d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
              fill="white"
            />
            <path
              d="M23.7246 21.4225L20.1348 24.9618L23.7246 28.501"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M28.709 25.0039L20.4294 25.0039"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <Swiper
          className=""
          spaceBetween={32}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Navigation, Autoplay]}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          // autoplay={{
          //   delay: 2500,
          //   disableOnInteraction: false,
          // }}
          speed={1000} // Transition speed in milliseconds
        >
          {data.data.attributes.Family.map((family, index) => (
            <SwiperSlide>
              <div className="w-full grid grid-cols-4 grid-rows-2 gap-8">
                <div className="w-full rounded-lg relative ">
                  <img
                    src={ApiUrl + family.Video_Image_One.data.attributes.url}
                    alt={
                      family.Video_Image_One.data.attributes.alternativeText ??
                      ""
                    }
                    style={{ cursor: "pointer" }}
                    className="w-full rounded-lg"
                  />
                  <div
                    onClick={() => openVideo(family.Video_Link_One)}
                    className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                  >
                    {playButton}
                  </div>
                </div>
                <div
                  style={{
                    backgroundImage: `url(${
                      ApiUrl + family.Video_Image_Two.data.attributes.url
                    })`,
                    backgroundSize: "cover", // Optional: Ensures the image covers the entire div
                    backgroundPosition: "center", // Optional: Centers the image within the div
                  }}
                  className="col-span-2 row-span-2 rounded-lg relative"
                >
                  {" "}
                  {/* <img
                    src={ApiUrl + family.Video_Image_Two.data.attributes.url}
                    alt=""
                    style={{ cursor: "pointer" }}
                    className="w-full"
                  />{" "} */}
                  <div
                    onClick={() => openVideo(family.Video_Link_Two)}
                    className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                  >
                    {" "}
                    {playButton}
                  </div>
                </div>
                <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
                  <h4 className="w-full text-[#FF7F45] leading-10 text-center text-3xl font-bold bg-clip-text">
                    {family.Text_Counter_One}
                  </h4>
                  <p className="text-xl text-center text-white font-semibold">
                    {family.Text_Heading_One}
                  </p>
                </div>
                <div className="bg-[#37424A] w-full rounded-lg flex flex-col justify-center">
                  <h4 className="w-full text-[#CF0072] leading-10 text-center text-3xl font-bold bg-clip-text">
                    {family.Text_Counter_Two}
                  </h4>
                  <p className="text-xl text-center font-semibold text-white">
                    {family.Text_Heading_Two}
                  </p>
                </div>
                <div className="bg-custom-secondary w-full rounded-lg relative rounded-lg">
                  <img
                    src={ApiUrl + family.Video_Image_Three.data.attributes.url}
                    alt={
                      family.Video_Image_Three.data.attributes
                        .alternativeText ?? ""
                    }
                    className="rounded-lg"
                    style={{ cursor: "pointer" }}
                  />
                  <div
                    onClick={() => openVideo(family.Video_Link_Three)}
                    className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                  >
                    {" "}
                    {playButton}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="swiper-button-next">
          <svg
            className=" rotate-180"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="25"
              cy="25"
              r="25"
              transform="rotate(-90 25 25)"
              fill="#37424A"
            />
            <path
              d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
              fill="white"
            />
            <path
              d="M26.2764 20.6282L29.8662 24.3605L26.2764 28.0928"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.291 24.4033L29.5706 24.4033"
              stroke="white"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_590_4369"
                x1="-4.74138"
                y1="5.55556"
                x2="58.7123"
                y2="10.0354"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#8884D5" />
                <stop offset="1" stop-color="#CF0072" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      <div className="w-full md:hidden">
        <Swiper
          className=""
          spaceBetween={32}
          slidesPerView={1}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Autoplay]}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          speed={1000} // Transition speed in milliseconds
        >
          {data.data.attributes.Family.map((family, index) => (
            <>
              <SwiperSlide>
                <div className="w-full flex">
                  <div className="w-full rounded-lg">
                    <img
                      src={ApiUrl + family.Video_Image_One.data.attributes.url}
                      alt={
                        family.Video_Image_One.data.attributes
                          .alternativeText ?? ""
                      }
                      style={{ cursor: "pointer" }}
                      className="w-full"
                    />
                    <div
                      onClick={() => openVideo(family.Video_Link_One)}
                      className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                    >
                      {playButton}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full flex">
                  <div className="w-full rounded-lg">
                    <img
                      src={ApiUrl + family.Video_Image_Two.data.attributes.url}
                      alt={
                        family.Video_Image_Two.data.attributes
                          .alternativeText ?? ""
                      }
                      style={{ cursor: "pointer" }}
                      className="w-full"
                    />
                    <div
                      onClick={() => openVideo(family.Video_Link_Two)}
                      className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                    >
                      {playButton}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="w-full flex">
                  <div className="w-full rounded-lg">
                    <img
                      src={
                        ApiUrl + family.Video_Image_Three.data.attributes.url
                      }
                      alt={
                        family.Video_Image_Three.data.attributes
                          .alternativeText ?? ""
                      }
                      style={{ cursor: "pointer" }}
                      className="w-full"
                    />
                    <div
                      onClick={() => openVideo(family.Video_Link_Three)}
                      className=" absolute w-full h-full top-0 rounded-lg bg-[#0000001c] flex justify-center items-center"
                    >
                      {playButton}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            </>
          ))}
        </Swiper>
      </div>

      <div className="min-md"></div>

      <VideoOverlay
        isOpen={isVideoOpen}
        onClose={closeVideo}
        videoUrl={currentVideoUrl}
      />
    </ParentContainer>
  );
};

export default Families;
