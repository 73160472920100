import React, { createContext, useEffect, useState } from "react";

export const LangContext = createContext();

const LangProvider = ({ children }) => {
  const [lang, setLang] = useState(localStorage.getItem("lang") || "en");

  useEffect(() => {
    localStorage.setItem("lang", lang);

    // Use window.location instead of location
    if (window.location.pathname.startsWith("/ar")) {
      const newPath = window.location.pathname.replace("/ar", "") || "/";
      window.history.pushState({}, "", newPath); // Update URL without reloading
    } else {
      const newPath = "/ar" + window.location.pathname;
      window.history.pushState({}, "", newPath); // Update URL without reloading
    }
  }, [lang]);

  const toggleLang = () => {
    setLang((prev) => (prev === "en" ? "ar" : "en"));
  };

  return (
    <LangContext.Provider value={{ lang, setLang, toggleLang }}>
      {children}
    </LangContext.Provider>
  );
};

export default LangProvider;
