import React, { useContext, useEffect, useState } from "react";
import HeroSection from "./AboutPage/HeroSection";
import VideoSection from "./AboutPage/VideoSection";
import Timeline from "./AboutPage/Timeline";
import CellSaveNumbers from "./AboutPage/CellSaveNumbers";
import WhySelect from "./AboutPage/WhySelect";
import Acceridation from "./AboutPage/Acceridation";
import TranslplantNumbers from "../components/TranslplantNumbers";
import assets from "../assets/assets";
import { ApiUrl } from "../components/ApiUrl";
import axios from "axios";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  const [aboutPage, setAboutPage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { lang, setLang } = useState();

  useEffect(() => {
    setLoading(true);
    const fetchHomePageData = async () => {
      let url =
        ApiUrl +
        "/api/about?populate=video_banner,Hero_Section,Hero_Section.Video,Timeline,Why_Select_Image,Faqs,Translpant_Numbers,Translpant_Numbers.image,CellSave_Licenses,CellSave_Licenses.Image_One,CellSave_Licenses.Image_Two,CellSave_Licenses.Image_Three,CellSave_Licenses.Image_Four,CellSave_Licenses.Image_Five,CellSave_Licenses.Images,CellSave_Licenses.Images.image,seo,seo.keywords&locale=ar";

      try {
        const response = await axios.get(url);
        setAboutPage(response.data);
        console.log(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching home page data:", error);
        setLoading(false);
      }
    };

    fetchHomePageData();
  }, [lang]);

  if (loading) {
    return <div className="h-[150vh]"></div>;
  }

  if (!aboutPage) {
    return <div>Error loading data.</div>;
  }

  return (
    <div className="bg-[#FBF7F5]" dir="rtl">
      {/* <Helmet>
        <title>{aboutPage?.data?.attributes?.seo?.title || ""}</title>
        <meta
          name="description"
          content={aboutPage?.data?.attributes?.seo?.meta_desc || ""}
        />
        <meta
          name="keywords"
          content={
            aboutPage?.data?.attributes?.seo?.keywords
              ?.map((keyword) => keyword.text)
              .join(", ") || ""
          }
        />
      </Helmet> */}
      <HeroSection
        data={aboutPage.data.attributes.Hero_Section}
        banner={aboutPage.data.attributes.video_banner}
      />
      <Timeline data={aboutPage.data.attributes.Timeline} />
      <CellSaveNumbers data={aboutPage.data.attributes} />
      <WhySelect data={aboutPage.data.attributes} />
      <TranslplantNumbers
        backgroundImage={
          ApiUrl +
          aboutPage.data.attributes.Translpant_Numbers.image.data.attributes.url
        }
        mobileBackgroundImage={assets.mt1}
        numberone={aboutPage.data.attributes.Translpant_Numbers.Number_One}
        numbertwo={aboutPage.data.attributes.Translpant_Numbers.Number_Two}
        numberthree={aboutPage.data.attributes.Translpant_Numbers.Number_Three}
        textone={aboutPage.data.attributes.Translpant_Numbers.Text_One}
        texttwo={aboutPage.data.attributes.Translpant_Numbers.Text_Two}
        textthree={aboutPage.data.attributes.Translpant_Numbers.Text_Three}
        title={aboutPage.data.attributes.Translpant_Numbers.Heading}
      />
      <Acceridation data={aboutPage.data.attributes} />
    </div>
  );
};

export default AboutPage;
