import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Limitless = ({ data }) => {
  const main = useRef();
  const parentRef = useRef();

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const boxes = gsap.utils.toArray(".box");
      boxes.forEach((box) => {
        gsap.fromTo(
          box,
          { x: -200, opacity: 0 },
          {
            x: 0,
            opacity: 1,

            scrollTrigger: {
              trigger: box,
              start: "bottom 100%",
              end: "top 100%",
              scrub: 1,
              //  markers: true,
            },
          }
        );
      });

      const circles = gsap.utils.toArray(".circle");
      circles.forEach((circle) => {
        gsap.fromTo(
          circle,
          { x: 500, scale: 0 },
          {
            x: 0,
            scale: 1,
            scrollTrigger: {
              trigger: circle,
              start: "bottom 80%",
              end: "top 80%",
              scrub: 1,
              // markers: true,
            },
          }
        );
      });

      const images = gsap.utils.toArray(".imgs");
      gsap.from(images, {
        opacity: 0,
        y: 50,
        stagger: 0.2,
        scrollTrigger: {
          trigger: parentRef.current,
          start: "top 70%",
          end: "bottom 70%",
          scrub: 1,
          toggleActions: "play none none none",
        },
      });
    }, main);

    return () => ctx.revert();
  }, []);

  const [beforeDelimiter, afterDelimiter] =
    data.Limitless_Treatments.split("||");
  return (
    <div ref={main}>
      <ParentContainer
        containerStyle={
          "bg-gradient-to-r  from-custom-primary to-custom-secondary   flex flex-wrap items-center max-lg:gap-4 overflow-hidden"
        }
        className=" "
      >
        <div className="w-4/6 box max-lg:w-full">
          <h3 className=" text-4xl font-bold text-white pb-3">
            {data.Limitless_Heading}
          </h3>
          <p className="text-base text-white w-4/5 max-md:w-full">
            {data.Limitless_Para}
          </p>
        </div>
        <div className="w-2/6 circle max-lg:w-full">
          <h3 className=" text-4xl  font-bold pb-5 uppercase text-right rtl:text-left">
            {beforeDelimiter} <br /> {afterDelimiter}
          </h3>
        </div>
      </ParentContainer>
    </div>
  );
};

export default Limitless;
