import React, { useState } from "react";
import ParentContainer from "../../components/ParentContainer";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { ApiUrl } from "../../components/ApiUrl";

const Form = ({ data }) => {
  const [phone, setPhone] = useState("");
  const [to, setTo] = useState("it@cellsave.com"); // Default recipient email
  const [subject, setSubject] = useState("");
  const [text, setText] = useState("");
  const [buttonText, setButtonText] = useState("تقديم");

  const handleEmailChange = (event) => {
    setTo(event.target.value); // Update the "to" state with the input value
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submission behavior

    // **Phone Number Validation**
    if (!phone) {
      alert("Phone number is required."); // Replace with a better UI element if desired
      return; // Prevent form submission
    }

    setButtonText("Sending..."); // Update button text

    const recipientEmails = to
      ? `${to},marketing@cellsave.com, rima.kobeissi@cellsave.com, catia.shamia@cellsave.com, Nadya.abbas@cellsave.com`
      : "marketing@cellsave.com, rima.kobeissi@cellsave.com, catia.shamia@cellsave.com, Nadya.abbas@cellsave.com";

    const formData = {
      to: recipientEmails,
      subject: subject || "New Contact Form Submission",
      text: text || "You have a new message from the Contact form.",
      html: `
        <p><strong>First Name:</strong> ${event.target["first-name"].value}</p>
        <p><strong>Last Name:</strong> ${event.target["last-name"].value}</p>
        <p><strong>Phone Number:</strong> ${event.target["phone-number"].value}</p>
        <p><strong>Email:</strong> ${event.target.email.value}</p>
        <p><strong>Message:</strong> ${event.target["message"].value}</p>
      `,
    };

    let emailSuccess = false;

    try {
      const response = await axios.post(
        ApiUrl + "/api/email/send", // Update this URL to your backend endpoint
        formData
      );

      console.log("Email sent successfully:", response.data);
      emailSuccess = true;
      setButtonText("Email Sent"); // Update button text on success
    } catch (emailError) {
      console.error(
        "Error sending email:",
        emailError.response ? emailError.response.data : emailError.message
      );
      setButtonText("Email Error! Try Again"); // Update button text in case of email error
    }

    if (emailSuccess) {
      try {
        // Submit data to the Salesforce endpoint
        const salesforceResponse = await axios.post(
          `${ApiUrl}/api/salesforce`,
          {
            FirstName: event.target["first-name"].value,
            LastName: event.target["last-name"].value,
            Email: event.target.email.value,
            MobilePhone: event.target["phone-number"].value,
            Origin_Program__c: "Contact",
            Description: event.target.message.value,
            Lead_Quality__c: "Warm",
            AttendingDoctor__c: "a00f400000XCppCAAT",
            Hospital_Clinics__c: "a01f400000KF5VMAA1",
            Program_Running__c: "Website",
            Origin_Hospital__c: "a01f400000KF5VMAA1",
            Origin_Doctor__c: "a00f400000XCppCAAT",
            Description: event.target["message"].value,
          }
        );

        console.log(
          "Data submitted to Salesforce successfully:",
          salesforceResponse.data
        );
      } catch (salesforceError) {
        console.error(
          "Error submitting data to Salesforce:",
          salesforceError.response
            ? salesforceError.response.data
            : salesforceError.message
        );
      }
    }
    setButtonText("Submission Successful"); // Update button text on Salesforce success

    // Reset button text after a delay
    setTimeout(() => {
      setButtonText("تقديم");
    }, 3000);
  };

  return (
    <ParentContainer containerStyle={"flex flex-wrap max-md:!pb-0"}>
      <div className="w-1/3 max-lg:w-full max-lg:pb-4">
        <h3 className="text-4xl font-bold pb-2 max-md:text-2xl">
          {data.Contact_Form_Heading}
        </h3>
        <p className="text-base">{data.Contact_Form_Para}</p>
      </div>
      <form  onSubmit={handleSubmit} className="w-2/3 max-lg:w-full">
        <div className="border-b pb-8 grid grid-cols-4 gap-4">
          <div className="col-span-2 w-full max-md:col-span-4">
            <label htmlFor="first-name" className="block mb-1">
              الاسم الأول
            </label>
            <input
              type="text"
              name="first-name"
              required
              id="first-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setSubject(`تواصل من ${e.target.value}`)}
            />
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="last-name" className="block mb-1">
              اسم العائلة
            </label>
            <input
              required
              type="text"
              name="last-name"
              id="last-name"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="phone-number" className="block mb-1">
              رقم الهاتف
            </label>
            <div dir="ltr">
              <PhoneInput
                country={"ae"}
                value={phone}
                required
                onChange={(phone) => setPhone(phone)}
                containerClass="w-full"
                inputProps={{
                  name: "phone-number",
                  required: true,
                  autoFocus: true,
                }}
              />
            </div>
          </div>

          <div className="col-span-2 max-md:col-span-4">
            <label htmlFor="email" className="block mb-1">
              عنوان البريد الإلكتروني
            </label>
            <input
              required
              type="email"
              name="email"
              id="email"
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
            />
          </div>

          <div className="col-span-4">
            <label htmlFor="topic" className="block mb-1">
              الموضوع
            </label>
            <select
              name="topic"
              required
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value="معلومات حول الخلايا الجذعية لحديثي الولادة">
                معلومات حول الخلايا الجذعية لحديثي الولادة
              </option>
              <option value="تسجيل التخزين">تسجيل التخزين</option>
              <option value="استفسارات أخرى">استفسارات أخرى</option>
              <option value="استفسارات تسويقية">استفسارات تسويقية</option>
              <option value="مقدمو الرعاية الصحية">مقدمو الرعاية الصحية</option>
              <option value="معلومات الفواتير">معلومات الفواتير</option>
            </select>
          </div>

          <div className="col-span-4">
            <label htmlFor="message" className="block mb-1">
              الرسالة
            </label>
            <textarea
              name="message"
              required
              rows={4}
              className="border border-gray-300 rounded-md px-3 py-2 w-full"
              onChange={(e) => setText(e.target.value)}
            ></textarea>
          </div>
        </div>

        <button
          type="submit"
          className="bg-gradient-to-r from-custom-primary to-custom-secondary hover:scale-110 transition-all text-white text-base px-8 py-2 rounded-3xl mt-6"
        >
          {
            buttonText /* استبدل هذا بقيمة زر الإرسال بالعربية مثلاً 'إرسال' إذا أردت */
          }
        </button>
      </form>
    </ParentContainer>
  );
};

export default Form;
