import React from "react";
import ParentContainer from "../components/ParentContainer";

const RefundPolicy = () => {
  return (
    <ParentContainer>
      <div className="flex flex-col gap-3" dir="rtl">
        <h1 className="text-5xl font-bold  text-right  w-1/2  max-md:text-2xl max-lg:w-full max-lg:mb-4">
          سياسة الاسترداد
        </h1>
        <p>
          1 - تشمل رسوم التسجيل تكاليف إنشاء الحساب والإدارة، ومجموعة الجمع،
          ورسوم توصيل العينة، وتكاليف النقل إلى المختبر عبر ساعي أو سائق معتمد.
          هذه الرسوم هي دفعة غير قابلة للاسترداد ويجب إرفاقها مع اتفاقية خدمة
          العميل الموقعة
        </p>
        <p>
          2 - في حال تم جمع العينة واستلامها ومعالجتها، ولكن قامت سيل سيف برفض
          العينة بسبب عدم الامتثال للمعايير التنظيمية، فلن يتم تخزين العينة،
          وسيتم استرداد رسوم التخزين للعميل. رسوم المعالجة غير قابلة للاسترداد
          إذا تمت معالجة واختبار عينة دم الحبل السري (وأو، إذا كان ذلك ينطبق،
          نسيج الحبل/نسيج المشيمة/نسيج الامينوسي المشيمي/وعاء الحبل)
        </p>
        <p>
          3 - في حال عدم معالجة أي جزء من حزمة العميل، يكون العميل مسؤولًا فقط
          عن دفع تكلفة المكونات التي تم معالجتها وتخزينها بنجاح. إذا لم يتم
          معالجة أي مكون من مكونات الحزمة، فلن يكون العميل مسؤولًا عن دفع
          تكلفته، وسيتم تعويض أي مبالغ مدفوعة وفقًا للشروط والأحكام المنصوص
          عليها في هذه الاتفاقية
        </p>
        <p>
          4 - سيتم استرداد المبالغ فقط عبر وسيلة الدفع الأصلية. يرجى السماح بمدة
          تصل إلى 45 يومًا لإتمام عملية التحوي
        </p>
        <p>
          5 - مجرد إجراء الدفع، سيتم إرسال إشعار تأكيد إلى العميل عبر البريد
          الإلكتروني خلال ساعة من استلام الدفع24
        </p>
        <p>
          6 - يمكن للعميل إلغاء طلبه خلال ساعة 24 وسيتم رد المبلغ إلى وسيلة
          الدفع المستخدمة في البداية من قبل العميل
        </p>
      </div>
    </ParentContainer>
  );
};

export default RefundPolicy;
