import React from "react";
import assets from "../../assets/assets";

const Voucher = () => {
  return (
    <div className="bg-white p-8 text-[#8884D5] max-lg:px-0">
      <div className="bg-[#F7F6FF]  rounded-lg">
        <img src={assets.voucher} alt="" />
      </div>
    </div>
  );
};

export default Voucher;
